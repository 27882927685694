import React from 'react';
import styled from 'styled-components';

const themeColor = '#383bcf'; // Theme color

const PaymentPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
`;

const PaymentPlanCard = styled.div`
  width: 280px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Default shadow */
  transition: transform 0.2s, box-shadow 0.2s; /* Added box-shadow to transition */
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px ${themeColor}; /* Shadow color changes on hover */
  }
`;

const PaymentPlanTitle = styled.h3`
  font-size: 22px;
  font-weight: bold;
  color: ${themeColor};
  margin: 0;
`;

const PaymentPlanPrice = styled.h4`
  font-size: 18px;
  margin-top: 10px;
  color: #666;
`;

const BestPriceTag = styled.span`
  background-color: ${themeColor};
  color: white;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
`;

const PaymentPlanFeatures = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  color: #888;
  margin-top: 10px;
`;

const PaymentPlanFeature = styled.li`
  font-size: 16px;
  margin-bottom: 5px;
`;

const PaymentPlanButton = styled.button`
  background-color: ${themeColor};
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.2s;
  &:hover {
    background-color: #218c8c;
  }
`;

const PaymentPlans = () => {
  const plans = [
    {
      title: 'Lifetime',
      price: '₹ 20,000 One time payment',
      features: ['After 3 days trial for the rest of your life.'],
      isBestPrice: true,
      deprecatedPrice: '₹ 40,000',
    },
    {
      title: 'Billed Yearly',
      price: '₹ 649/m',
      features: ['After 3 days trial'],
    },
    {
      title: 'Billed Half Yearly',
      price: '₹ 699/m',
      features: ['After 3 days trial'],
    },
    {
      title: 'Billed Quarterly',
      price: '₹ 749/m',
      features: ['After 3 days trial'],
    },
    {
      title: 'Billed Monthly',
      price: '₹ 849/m',
      features: ['After 3 days trial'],
    },
];

  return (
    <PaymentPlansContainer>
      {plans.map((plan) => (
        <PaymentPlanCard key={plan.title}>
          {plan.isBestPrice && <BestPriceTag>Best Price</BestPriceTag>}
          <PaymentPlanTitle>{plan.title}</PaymentPlanTitle>
          <PaymentPlanPrice>{plan.price}</PaymentPlanPrice>
          {plan.isBestPrice && (
            <PaymentPlanPrice style={{ textDecoration: 'line-through', color: '#888' }}>
              {plan.deprecatedPrice}
            </PaymentPlanPrice>
          )}
          <PaymentPlanFeatures>
            {plan.features.map((feature, index) => (
              <PaymentPlanFeature key={index}>{feature}</PaymentPlanFeature>
            ))}
          </PaymentPlanFeatures>
          <PaymentPlanButton>Choose Plan</PaymentPlanButton>
        </PaymentPlanCard>
      ))}
    </PaymentPlansContainer>
  );
            }

export default PaymentPlans ;
