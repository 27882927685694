import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import AppStoreButton from "../images/SocialIcons/App Store Button.png";
import PlayStoreButton from "../images/SocialIcons/Play Store Button.png";
import instagram from "../images/SocialIcons/instagram.png";
import twitter from "../images/SocialIcons/twitter.png";
import emailicon from "../images/emailicon.png";
import FitsilhouetteWithShadow from "../images/Fit silhouetteWithShadow.png";

function Footer() {
  const navigate = useNavigate();

  const handleInstagramButtonClick = () => {
    // Replace with your Instagram profile link
    window.open("https://www.instagram.com/fit.silhouette/", "_blank");
  };

  const handleTwitterButtonClick = () => {
    // Replace with your Twitter profile link
    window.open("https://twitter.com/fit_silhouette", "_blank");
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        backgroundColor: "#383bcf",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {window.innerWidth > 768 ? (
        <RowDiv>
          <ColumnDiv style={{ alignItems: "center" }}>
            <LogoName>
              <img
                src={FitsilhouetteWithShadow}
                width={"100%"}
                height={"100%"}
              />
            </LogoName>
            <div
              style={{
                display: "flex",
                marginTop: "3vh",
              }}
            >
              <ImageContainer onClick={handleTwitterButtonClick}>
                <img src={twitter} height={"100%"} width={"100%"} />
              </ImageContainer>
              <ImageContainer
                style={{ marginLeft: "2.11vw" }}
                onClick={handleInstagramButtonClick}
              >
                <img src={instagram} height={"100%"} width={"100%"} />
              </ImageContainer>
            </div>
          </ColumnDiv>
          <ColumnDiv style={{ marginLeft: "7.92vw" }}>
            <Heading>Information</Heading>
            <Points
              onClick={() => {
                navigate("about");
              }}
            >
              About Us
            </Points>

            {/* <Points
              onClick={() => {
                navigate("contactus");
              }}
            >
              Contact Us
            </Points> */}
            <Points
              onClick={() => {
                navigate("terms");
              }}
            >
              Terms and Conditions
            </Points>
            <Points
              onClick={() => {
                navigate("privacy");
              }}
            >
              Privacy Policy
            </Points>
            <Points
              onClick={() => {
                navigate("cancellationandrefund");
              }}
            >
              Cancellation and Refund Policy
            </Points>
            <Points
              onClick={() => {
                navigate("shippingpolicy");
              }}
            >
              Shipping and Delivery
            </Points>
          </ColumnDiv>

          <ColumnDiv style={{ marginLeft: "7.92vw" }}>
            <Heading>Contact Us</Heading>

            <RowDiv style={{ marginTop: "0" }}>
              <div style={{ width: "1.05vw", height: "1.05vw" }}>
                <img src={emailicon} width={"100%"} height={"100%"} />
              </div>
              <ColumnDiv
                style={{ marginLeft: "14px" }}
                onClick={() => {
                  window.location.href = `mailto:info@fitsilhouette.com`;
                }}
              >
                <ContactUsText>Email Id</ContactUsText>
                <ContactUsText style={{ marginTop: "4px" }}>
                  info@fitsilhouette.com
                </ContactUsText>
              </ColumnDiv>
            </RowDiv>
          </ColumnDiv>

          <ColumnDiv style={{ marginLeft: "7.92vw", marginRight: "20px" }}>
            <StayInTouchText>Stay in Touch</StayInTouchText>
            <StayInTouchTextDesscription>
              Enter your email to receive daily updates and get great deals on
              all items.
            </StayInTouchTextDesscription>
            <StayInTouchInput placeholder="Email address" />
            <StayInTouchButton>
              <StayInTouchButtonText>Subscribe</StayInTouchButtonText>
            </StayInTouchButton>
            <div style={{ marginTop: "3.37vh", display: "flex" }}>
              <img
                src={PlayStoreButton}
                style={{
                  height: "auto",
                  width: "7.06vw",
                }}
              />

              <img
                src={AppStoreButton}
                style={{
                  height: "auto",
                  width: "7.06vw",
                  marginLeft: "2.11vw",
                }}
              />
            </div>
          </ColumnDiv>
        </RowDiv>
      ) : (
        <ColumnDiv style={{ width: "90vw" }}>
          <RowDiv>
            <ColumnDiv style={{ width: "40%" }}>
              <Heading>Follow Us</Heading>
              <div
                style={{
                  display: "flex",
                  marginBottom: "15px",
                }}
              >
                <ImageContainer onClick={handleTwitterButtonClick}>
                  <img src={twitter} width={"100%"} height={"100%"} />
                </ImageContainer>
                <ImageContainer
                  style={{ marginLeft: "30px" }}
                  onClick={handleInstagramButtonClick}
                >
                  <img src={instagram} width={"100%"} height={"100%"} />
                </ImageContainer>
              </div>
              <StoreButtonImageContainer>
                <img src={PlayStoreButton} height={"100%"} width={"100%"} />
              </StoreButtonImageContainer>
              <StoreButtonImageContainer>
                <img src={AppStoreButton} height={"100%"} width={"100%"} />
              </StoreButtonImageContainer>
            </ColumnDiv>
            <ColumnDiv style={{ marginLeft: "10%", width: "50%" }}>
              <Heading>Information</Heading>
              <Points
                onClick={() => {
                  navigate("about");
                }}
              >
                About Us
              </Points>

              {/* <Points
              onClick={() => {
                navigate("contactus");
              }}
            >
              Contact Us
            </Points> */}
              <Points
                onClick={() => {
                  navigate("terms");
                }}
              >
                Terms & Conditions
              </Points>
              <Points
                onClick={() => {
                  navigate("privacy");
                }}
              >
                Privacy Policy
              </Points>
              <Points
                onClick={() => {
                  navigate("cancellationandrefund");
                }}
              >
                Cancellation & Refund
              </Points>
              <Points
                onClick={() => {
                  navigate("shippingpolicy");
                }}
              >
                Shipping & Delivery
              </Points>
            </ColumnDiv>
          </RowDiv>
          <RowDiv>
            <ColumnDiv style={{ width: "40%" }}>
              <Heading>Contact Us</Heading>

              <Points
                style={{
                  fontFamily: "Gilroy-Regular, Helvetica",
                  fontWeight: "200",
                  fontSize: "12px",
                }}
                onClick={() => {
                  window.location.href = `mailto:info@fitsilhouette.com`;
                }}
              >
                ✉ Email :<br /> help@fitsilhouette.com
              </Points>
            </ColumnDiv>

            <ColumnDiv style={{ marginLeft: "10%", width: "50%" }}>
              <StayInTouchText>Stay in Touch</StayInTouchText>
              <StayInTouchTextDesscription>
                Enter your email to receive daily updates and get great deals on
                all items.
              </StayInTouchTextDesscription>
              <StayInTouchInput placeholder="Email address" />
              <StayInTouchButton>
                <StayInTouchButtonText>Subscribe</StayInTouchButtonText>
              </StayInTouchButton>
            </ColumnDiv>
          </RowDiv>
        </ColumnDiv>
      )}

      <RowDiv
        style={{
          marginTop: window.innerWidth > 768 ? "6vh" : "30px",
          marginBottom: window.innerWidth > 768 ? "2vh" : "30px",
        }}
      >
        <Copyright>
          <br />
          Copyright
        </Copyright>
        <Copyright style={{ marginLeft: "1.20vw" }}>
          <br />©
        </Copyright>
        <Copyright style={{ marginLeft: "1.20vw" }}>
          <br />
          Fit Silhouette 2023, All Rights Reserved.
        </Copyright>
      </RowDiv>
    </div>
  );
}

export default Footer;

const RowDiv = styled.div`
  display: flex;
  text-align: center;
  /* width: 80%; */
  margin-top: 6.75vh;
  /* justify-content: space-evenly; */

  @media (max-width: 768px) {
    margin-top: 40px;
    justify-content: space-between;
  }
`;

const ColumnDiv = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;

  @media (max-width: 768px) {
    justify-content: start;
    align-items: start;
  }
`;

const ImageContainer = styled.div`
  background-size: 100% 100%;
  width: 1.98vw;
  height: 1.98vw;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;
const LogoName = styled.div`
  background-size: 100% 100%;
  background-size: 100% auto;
  object-fit: cover;
  width: 9.7vw;
  height: auto;
  margin-top: -3.79vh;

  @media (max-width: 768px) {
    width: 110px;
    height: 13px;
    margin-top: -30px;
  }
`;

const StoreButtonImageContainer = styled.div`
  margin-bottom: 2.95vh;
  background-color: #000000;
  /* border: 1px solid; */

  border-color: transparent;
  background-color: transparent;
  overflow: hidden;
  /* position: relative; */
  height: 4.5vh;
  width: 7.06vw;
  cursor: pointer;

  @media (max-width: 768px) {
    margin-bottom: 10px;
    height: auto;
    width: 50%;
  }
`;

const Heading = styled.div`
  color: #ffffff;
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 1.32vw;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 2.25vh;

  @media (max-width: 768px) {
    font-size: 21px;
    margin-bottom: 15px;
  }
`;

const StayInTouchText = styled.div`
  -webkit-text-stroke: 0.5px #ffffff;
  color: #ffffff;
  font-family: Gilroy-Light, Helvetica;
  font-size: 2.11vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 21px;
    margin-bottom: 15px;
  }
`;

const StayInTouchTextDesscription = styled.div`
  color: #ffffff;
  font-family: Gilroy-Light, Helvetica;
  font-size: 0.92vw;
  font-weight: 200;
  letter-spacing: 0;
  line-height: normal;
  width: 21.1vw;
  margin-top: 1.26vh;

  @media (max-width: 768px) {
    font-size: 13px;
    width: 90%;
    margin-top: 0px;
  }
`;

const StayInTouchInput = styled.input`
  background-color: #ffffff;
  border-radius: 32px;
  border-color: transparent;
  height: 3.8vh;
  max-width: 17.8vw;
  margin-top: 2.53vh;
  color: black;
  font-family: Gilroy-Light, Helvetica;
  font-size: 1.05vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2.81vh;
  white-space: nowrap;

  padding-left: 1.51vw;

  /* ::placeholder {
    color: black;
  } */

  @media (max-width: 768px) {
    font-size: 11px;
    height: 22px;
    max-width: 90%;
  }
`;

const StayInTouchButton = styled.button`
  background-color: #ffffff;
  border-color: #ffffff;
  border-color: transparent;
  height: 3.8vh;
  width: 9.51vw;
  margin-top: 2.23vh;
  border-radius: 12px;
  box-shadow: 0px 0px 4px #00000033;
  display: inline-flex;
  display: flex;
  justify-content: center;

  @media (max-width: 768px) {
    font-size: 17px;
    height: 22px;
    width: 60%;
    margin-top: 15px;
  }
  /* padding: 12px 60px; */
`;

const StayInTouchButtonText = styled.div`
  color: #383bcf;
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 0.92vw;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  align-self: center;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;

const Points = styled.div`
  color: #ffffff;
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 1.05vw;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  margin-bottom: 2.25vh;
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const ContactUsText = styled.div`
  color: #ffffff;
  font-family: Gilroy-Regular, Helvetica;
  font-size: 1.05vw;
  font-weight: 300;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;
  /* margin-bottom: 3.37vh; */
  cursor: pointer;

  @media (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 20px;
  }
`;

const Copyright = styled.h1`
  color: #ffffff;
  font-family: "Square721 BT-Roman", Helvetica;
  font-size: 0.66vw;
  font-weight: 100;
  letter-spacing: 0;
  line-height: normal;
  white-space: nowrap;

  @media (max-width: 768px) {
    font-size: 11px;
  }
`;
