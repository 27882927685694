import React from "react";
import styled from "styled-components";
import OurFeaturesCard from "./Cards/OurFeaturesCard";
import image4 from "../images/image_4.png";
import image5 from "../images/image_5.png";
import JoinNowScreen from "./JoinNowScreen";

function OurFeatures({ container }) {
  return (
    <div
      ref={container}
      style={{
        backgroundColor: "white",
        // paddingBottom: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <RowDiv>
        <SloganDiv>
          <GreyDiv>
            <AppTitle>Fitness Tailored to your Goal</AppTitle>

            <AppDescrption>
              Fit Silhouette App Offers an expansive array of features, setting
              it apart from the rest. It's your ultimate companion for
              conquering the challenges of your fitness journey. With an
              extensive library of workout guides tailored to help you build
              muscle, boost strength, or attain a lean physique, along with more
              than 1500+ meal plans, spanning 22+ different diets, smart meal
              planning is at your fingertips.
            </AppDescrption>
          </GreyDiv>

          <OurFeaturesCard />

          <RowDiv
            style={{
              flexDirection: window.innerWidth > 768 ? "row" : "column",
              alignItems: "center",
              width: "100%",
              marginTop: window.innerWidth > 768 ? "9vh" : "40px",
              backgroundColor: "#f6f6f6",
            }}
          >
            <ColumnDiv>
              <AppTitle
                style={{
                  marginTop: window.innerWidth > 768 ? 0 : "30px",
                  color: "#000000",
                }}
              >
                #NourishOnTheGo
              </AppTitle>
              <AboutDescription>
                Your busy schedule doesn't stand a chance against our Online
                Smart Food Delivery service. We bring nourishing meals right to
                your doorstep, ensuring you never skip a beat or a bite. Your
                health is our priority, and we're here to make sure you stay
                fueled and focused, no matter how hectic life gets.
              </AboutDescription>
            </ColumnDiv>
            <div
              style={{
                height: window.innerWidth > 768 ? "auto" : "auto",
                width: window.innerWidth > 768 ? "21.66vw" : "70vw",
                marginLeft: window.innerWidth > 768 ? "5.28vw" : "0px",
                marginTop: window.innerWidth > 768 ? "11.53vh" : "20px",
                marginBottom: window.innerWidth > 768 ? "11.53vh" : "30px",
              }}
            >
              <img
                src={image4}
                alt="Description of the image"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
          </RowDiv>

          <RowDiv
            style={{
              flexDirection: window.innerWidth > 768 ? "row" : "column-reverse",
              alignItems: "center",
              width: "100%",
              marginTop: window.innerWidth > 768 ? "7.03vh" : "40px",
            }}
          >
            <div
              style={{
                height: window.innerWidth > 768 ? "auto" : "auto",
                width: window.innerWidth > 768 ? "21.66vw" : "70vw",
                marginRight: window.innerWidth > 768 ? "5.28vw" : "0px",
                marginTop: window.innerWidth > 768 ? "11.53vh" : "20px",
                marginBottom: window.innerWidth > 768 ? "11.53vh" : "30px",
              }}
            >
              <img
                src={image5}
                alt="Description of the image"
                style={{ height: "100%", width: "100%" }}
              />
            </div>
            <ColumnDiv>
              <AppTitle
                style={{
                  marginTop: window.innerWidth > 768 ? 0 : "30px",
                  color: "#000000",
                }}
              >
                #StyleInMotion
              </AppTitle>
              <AboutDescription>
                Elevate your style and performance with our Premium Clothing and
                Gym Gears collection. From athleisure that seamlessly blends
                fashion and function to high-performance workout gear designed
                to boost your fitness journey, we're here to elevate your every
                move. Step into excellence, step into confidence.
              </AboutDescription>
            </ColumnDiv>
          </RowDiv>

          <AppTitle
            style={{ marginTop: window.innerWidth > 768 ? "7.59vh" : "40px" }}
          >
            What People Say About Us!!
          </AppTitle>

          <RowDiv
            style={{
              flexDirection: window.innerWidth > 768 ? "row" : " column",
            }}
          >
            <RatingCard>
              <RatingCardText2
                style={{
                  fontSize: window.innerWidth > 768 ? "1.65vw" : "27px",
                  color: "#FFB301",
                }}
              >
                ★★★★★
              </RatingCardText2>

              <RatingCardText1>LIFE-CHANGING APP!</RatingCardText1>
              <RatingCardText2>
                "This app has truly changed my life. I used to struggle to find
                time for the gym, but now I can work out whenever and wherever I
                want. The customizable workouts and nutrition plans make it easy
                to achieve my fitness goals. I can't thank the creators enough!"
              </RatingCardText2>
              <RatingCardText2
                style={{
                  marginBottom: "24px",
                  fontSize: window.innerWidth > 768 ? "1.05vw" : "15px",
                }}
              >
                Cameron Williamson via AppStore
              </RatingCardText2>
            </RatingCard>

            <RatingCard style={{ marginLeft: "1.05vw" }}>
              <RatingCardText2
                style={{
                  fontSize: window.innerWidth > 768 ? "1.65vw" : "27px",
                  color: "#FFB301",
                }}
              >
                ★★★★★
              </RatingCardText2>
              <RatingCardText1>CONVENIENT AND EFFECTIVE</RatingCardText1>
              <RatingCardText2>
                "As a busy professional, I don't have a lot of time for the gym.
                Intermittent Fasting in app has been a game-changer for me. I
                can keep track of my fasting as well as eating time anywhere.
                The results speak for themselves!"
              </RatingCardText2>
              <RatingCardText2
                style={{
                  marginBottom: "24px",
                  fontSize: window.innerWidth > 768 ? "1.05vw" : "15px",
                }}
              >
                Robert Fox via Play Store
              </RatingCardText2>
            </RatingCard>

            <RatingCard style={{ marginLeft: "1.05vw" }}>
              <RatingCardText2
                style={{
                  fontSize: window.innerWidth > 768 ? "1.65vw" : "27px",
                  color: "#FFB301",
                }}
              >
                ★★★★★
              </RatingCardText2>
              <RatingCardText1>INCREDIBLE RESULTS!</RatingCardText1>
              <RatingCardText2>
                "I was skeptical about using a fitness app, but this one has
                blown me away. I am most impressed by all the different kind of
                trackers in one app, including the period tracker. The tracking
                features are a game-changer, and the community support keeps me
                motivated."
              </RatingCardText2>
              <RatingCardText2
                style={{
                  marginBottom: "24px",
                  fontSize: window.innerWidth > 768 ? "1.05vw" : "15px",
                }}
              >
                Jacob Jones via Play Store
              </RatingCardText2>
            </RatingCard>
          </RowDiv>

          <JoinNowScreen />

          {/* <div
            style={{
              justifyContent: "space-evenly",
              width: window.innerWidth > 768 ? "50vw" : "90vw",
              marginBottom: "100px",
              display: "flex",
              flexDirection: "row"
              alignItems: "top",
              backgroundColor: "white",
              borderRadius: "10px",
              boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
              padding: "16px",
            }}
          >
            <Table>
              <thead>
                <TableRow>
                  <TableHeader>Comparision</TableHeader>
                  <TableHeader>Fit Silhouette</TableHeader>
                  <TableHeader>Others</TableHeader>
                </TableRow>
              </thead>
              <tbody>
                <TableRow>
                  <TableData> Workout Mode & Plans</TableData>
                  <TableData>✅</TableData>
                  <TableData>✅</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Reminder Functionality</TableData>
                  <TableData>✅</TableData>
                  <TableData>✅</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Data Insights and Analytics</TableData>
                  <TableData>✅</TableData>
                  <TableData>✅</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Artificial Intelligence Guidance</TableData>
                  <TableData>✅</TableData>
                  <TableData>❌</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Restaurant Integration</TableData>
                  <TableData>✅</TableData>
                  <TableData>❌</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Realtime Health Tracking</TableData>
                  <TableData>✅</TableData>
                  <TableData>❌</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Premium Access to Expert Content</TableData>
                  <TableData>✅</TableData>
                  <TableData>❌</TableData>
                </TableRow>
                <TableRow>
                  <TableData> Community Support</TableData>
                  <TableData>✅</TableData>
                  <TableData>❌</TableData>
                </TableRow>
              </tbody>
            </Table>
           
          </div> */}

          {/* <Slogan
            style={{
              fontSize: 38,
              margin: 60,
              color: "white",
              marginTop: 30,
              marginBottom: 30,
              width: "70%",
              lineHeight: 1.3,
              letterSpacing: "0.06ch",
              fontWeight: "700",
              textShadow: " 0px 0px 100px #fff",
            }}
          >
            Already a Member?
          </Slogan>
          <Input
            //   {...register("LastName")}
            placeholder="Email (@gmail.com)"
          />
          <Input
            //   {...register("LastName")}
            placeholder="Phone Number"
          />
          <ContactUs>Contact Us</ContactUs> */}

          {/* <div style={{ height: 60 }}></div>
        <RowDiv style={{ justifyContent: "space-evenly", width: "100vw" }}>
          <OurFeaturesCard
            heading={"✅ Diet Plans & Nutrition"}
            description={`Fit Silhouette offers personalised diet plans tailored to your
                goals and dietary preferences`}
          />

          <OurFeaturesCard
            heading={"✅ Diet Plans & Nutrition"}
            description={`Fit Silhouette offers personalised diet plans tailored to your
                goals and dietary preferences`}
          />
        </RowDiv> */}

          {/* <div style={{ height: 100 }}></div> */}
          {/* <h3></h3> */}
        </SloganDiv>
      </RowDiv>
    </div>
  );
}

export default OurFeatures;

const RowDiv = styled.div`
  /* min-height: 90vh; */
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: top;
  background-color: white;
  /* background: linear-gradient(to bottom, #0f0235 68%, #fff 70%); */
  text-align: center;
`;

const ColumnDiv = styled.div`
  /* min-height: 90vh; */
  /* flex: 1; */
  display: flex;
  flex-direction: Column;
  justify-content: center;
  align-items: start;
  /* background: linear-gradient(to bottom, #0f0235 68%, #fff 70%); */
  text-align: center;
  @media (max-width: 768px) {
    align-items: center;
  }
`;

// const Table = styled.table`
//   border-collapse: collapse;
//   width: 100%;
// `;

// const TableHeader = styled.th`
//   background-color: #f2f2f2;
//   border: 1px solid #fff;
//   padding: 10px;

//   text-align: left;
// `;

// const TableData = styled.td`
//   border: 1px solid #fff;
//   padding: 8px;
//   text-align: left;
// `;
// const TableRow = styled.tr``;

const SloganDiv = styled.div`
  display: flex;
  /* max-width: 50vw; */
  flex-direction: column;
  justify-content: top;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;

  @media (max-width: 768px) {
    margin-left: 10px;
    margin-right: 10px;
  }
  /* background-color: white; */
`;

const GreyDiv = styled.div`
  background-color: #f6f6f6;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  /* @media (max-width: 768px) {
    height: 100%;
  } */
`;

const AppTitle = styled.p`
  /* width: 42.4vw; */
  font-size: 2.11vw;
  margin-top: 7.87vh;
  height: 5.48vh;
  color: #333333;
  font-family: Gilroy-SemiBold, Helvetica;
  font-weight: 700;
  text-align: center;
  letter-spacing: 0;
  line-height: normal;
  /* margin-left: 30; */
  /* margin-top: 100px; */
  /* margin-bottom: 100px; */
  // if mobile
  @media (max-width: 768px) {
    font-size: 25px;
    max-width: 80vw;
    margin-top: 50px;
    margin-bottom: 30px;
  }
`;

const AppDescrption = styled.p`
  margin-bottom: 7.87vh;
  margin-top: -16px;
  color: #333333;
  font-size: 1.58vw;
  width: 67.6vw;
  height: 100%;
  font-family: Gilroy-Light, Helvetica;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  line-height: 3.93vh;

  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 18px;
    line-height: normal;
    width: 90%;
    margin-bottom: 30px;
  }
`;

const AboutDescription = styled.div`
  margin-top: -30px;
  color: #000000;
  text-align: start;
  font-family: Gilroy-Light, Helvetica;
  font-size: 1.58vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 4.21vh;
  width: 36.37vw;
  /* margin-left: 30; */
  /* margin-bottom: 100px; */
  // if mobile

  @media (max-width: 768px) {
    font-size: 18px;
    line-height: normal;
    width: 90%;
    margin-bottom: 30px;
    text-align: center;
  }
`;

const RatingCard = styled.div`
  margin-top: 3.65vh;
  background-color: #f3eee9;
  border-radius: 12px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 2.11vw;
  max-width: 25.03vw;

  @media (max-width: 768px) {
    margin-top: 30px;
    max-width: 90vw;
    margin-bottom: 10px;
  }
`;

const RatingCardText1 = styled.div`
  margin-top: 3.23vh;
  color: #555547;
  font-family: Source Sans Pro-Bold, Helvetica;
  font-size: 1.32vw;
  font-weight: 700;
  height: 4.21vh;
  letter-spacing: 0;
  line-height: 4.21vh;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 768px) {
    margin-top: 10px;
    font-size: 20px;
  }
`;
const RatingCardText2 = styled.div`
  margin-top: 2.95vh;
  color: #555547;
  font-family: Source Sans Pro-Regular, Helvetica;
  font-size: 1.18vw;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 3.37vh;
  text-align: center;
  top: 0;
  width: 20.73vw;

  @media (max-width: 768px) {
    margin-top: 15px;
    width: 80vw;
    font-size: 16px;
  }
`;
