import React, { useState, useEffect } from "react";

import styled from "styled-components";
import Slider1 from "../../images/Slider1.png";
import Slider2 from "../../images/Slider2.png";
import Slider3 from "../../images/Slider3.png";
import Slider4 from "../../images/Slider4.png";
import Slider5 from "../../images/Slider5.png";
import Slider6 from "../../images/Slider6.png";
import Slider7 from "../../images/Slider7.png";
// import HomePage from "./HomePage";
// import SecondPage from "./SecondPage";
// import ThirdPage from "./ThirdPage";
// import FourthPage from "./FourthScreen";
// import FifthPage from "./FifthPage";
// import SixthPage from "./SixthPage";

function Home({ container }) {
  const images = [
    Slider1,
    Slider2,
    Slider3,
    Slider4,
    Slider5,
    Slider6,
    Slider7,
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handleDotClick = (index) => {
    setCurrentIndex(index);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const autoSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  useEffect(() => {
    const autoSlideInterval = setInterval(autoSlide, 3500); // Auto-slide every 3 seconds

    return () => {
      clearInterval(autoSlideInterval);
    };
  }, []);

  // const isAtFirstView = activeView === 0;
  // const isAtLastView = activeView === totalViews - 1;

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: "6.18vh",
      }}
    >
      <RowContainer>
        {/* <FadeElement /> */}

        {/* <NavigationButton onClick={prevSlide}>&#8249;</NavigationButton> */}
        {window.innerWidth > 768 ? (
          <Slogan
            onClick={prevSlide}
            style={{
              color: "black",
              fontSize: "4.23vw",
              marginRight: "2.64vw",
              fontWeight: "200",
              cursor: "pointer",
            }}
          >
            &#8249;
          </Slogan>
        ) : null}

        <div>
          <ImageSliderContainer>
            <ImageSlider currentIndex={currentIndex}>
              {images.map((image, index) => (
                <Image
                  key={index}
                  src={image}
                  alt={`Image ${index + 1}`}
                  // style={{
                  //   transform: `translateX(-${currentIndex * 100}%`,
                  // }}
                />
              ))}
            </ImageSlider>
          </ImageSliderContainer>
          <DotsContainer>
            {images.map((_, index) => (
              <Dot
                key={index}
                active={index === currentIndex}
                onClick={() => handleDotClick(index)}
              />
            ))}
          </DotsContainer>
        </div>
        {/* <CardViewGetStarted totalViews={totalViews} activeView={activeView}>
            <HomePage goToView={goToView} />
            <SecondPage goToView={goToView} />
            <ThirdPage goToView={goToView} />
            <FourthPage goToView={goToView} />
            <FifthPage goToView={goToView} />
            <SixthPage goToView={goToView} scroller={scroller} />
          </CardViewGetStarted>
           */}

        {/* {window.innerWidth < 768 ? (
          <div
            style={{
              width: "100%",
              display: "flex",
              marginTop: "-40px",
              justifyContent: "space-between",
            }}
          >
            <Slogan
              onClick={slideBackward}
              style={{
                color: isAtLastView ? "transparent" : "black",
                fontSize: 20,
                marginLeft: 30,
                fontWeight: "bold",
                cursor: "pointer",
                // display: "inline-block",
              }}
            >
              {"<- Previous"}
            </Slogan>
            <Slogan
              onClick={slideForward}
              style={{
                color: isAtLastView ? "transparent" : "black",
                fontSize: 20,
                marginLeft: 30,
                fontWeight: "bold",
                cursor: "pointer",
                // display: "inline-block",
                // transform: "scaleX(-1)",
              }}
            >
              {"Next ->"}
            </Slogan>
          </div>
        ) : null}
         */}
        {window.innerWidth > 768 ? (
          <Slogan
            onClick={nextSlide}
            style={{
              color: "black",
              fontSize: "4.23vw",
              marginLeft: "2.64vw",
              fontWeight: "200",
              cursor: "pointer",
            }}
          >
            &#8250;
          </Slogan>
        ) : null}
        {/* 
      <Slogan
        style={{
          textShadow: " 0px 0px 100px #fff",
          lineHeight: 1.2,
          letterSpacing: "0.07ch",
        }}
      >
       ❝ Our mission is to empower people on their journey
        towards a healthier, happier themselves! ❞
      </Slogan> */}
      </RowContainer>
    </div>
  );
}

export default Home;

const RowContainer = styled.div`
  /* width: 90%; */
  max-height: 92.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;

  @media (max-width: 768px) {
    max-height: 100%;
  }
`;

const ImageSliderContainer = styled.div`
  width: 59.7vw;
  height: auto;

  /* width: 904px;
  height: 560px; */
  overflow: hidden;
  /* padding: 4.64vh 6.93vw; */

  @media (max-width: 768px) {
    width: 100%;
    height: auto;
  }
`;

const ImageSlider = styled.div`
  display: flex;
  transition: transform 0.3s ease;
  transform: translateX(${(props) => -props.currentIndex * 59.7}vw);

  @media (max-width: 768px) {
    transform: translateX(${(props) => -props.currentIndex * 100}%);
  }

  /* transform: translateX(${(props) => -props.currentIndex * 904}px); */
`;

const Image = styled.img`
  width: 59.7vw;
  height: auto;
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
  /* width: 904px;
  height: 560px; */
  /* margin-left: 6.93vw; */
`;

const DotsContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 3.23vh;
  margin-bottom: 7.87vh;

  @media (max-width: 768px) {
    margin-top: 30px;
    margin-bottom: 10px;
  }
`;

const Dot = styled.div`
  width: ${(props) => (props.active ? "1.98vw" : "0.66vw")};
  height: 1.4vh;
  border-radius: ${(props) => (props.active ? "30%" : "50%")};
  background-color: ${(props) => (props.active ? "#383bcf" : "#d9d9d9")};
  margin: 0 5px;
  transition: transform 0.3s ease-in-out;
  cursor: pointer;

  @media (max-width: 768px) {
    width: ${(props) => (props.active ? "25px" : "10px")};
    height: 10px;
  }
`;

const Slogan = styled.h1`
  font-size: 34px;
  max-width: 50vw;
  color: black;
  font-family: "Gilroy", sans-serif;
  font-weight: 600;
  text-align: center;
  text-shadow: " 0px 0px 100px #000";
  /* margin-left: 30; */
  margin-top: 100px;
  margin-bottom: 100px;
  // if mobile
  @media (max-width: 768px) {
    font-size: 25px;
    max-width: 90vw;
    margin-top: 70px;
    margin-bottom: 70px;
  }
`;
