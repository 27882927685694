import { signInWithPopup } from "firebase/auth";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { auth, provider } from "../Components/firebaseConfig";
import FitSilhouetteNameDark from "../images/FitSilhouetteNameDark.png";
import logo from "../images/logo.webp";
function Header({ HomeContainer, Whatwearecontainer, OurFeaturesContainer }) {
  const navigate = useNavigate();
  const location = useLocation();

  // For Header Button Navigation

  const scrollToContainer = (ref) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  };

  const handleButtonClick = (destination) => {
    if (location.pathname === "/") {
      scrollToContainer(destination);
    } else {
      if (destination == HomeContainer) {
        navigate("/", { state: { scrollTo: "HomeContainer" } });
      } else if (destination == Whatwearecontainer) {
        navigate("/", { state: { scrollTo: "Whatwearecontainer" } });
      } else if (destination == OurFeaturesContainer) {
        navigate("/", { state: { scrollTo: "OurFeaturesContainer" } });
      }
    }
  };

  // For Header Button Color

  const [activeText, setActiveText] = useState(1);
  const [value ,setValue] =useState('');
  const handleHeaderTextClick = (buttonId) => {
    setActiveText(buttonId);
  };

  // For Drawer in mobile

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  const login = () => {
   console.log("kmckdcmdlc")
    signInWithPopup(auth,provider).then((data)=>{
      setValue(data.user.email);
      localStorage.setItem("email",data.user.email);
      navigate("/paymentspage");
    })
  }

  return (
    <HeaderWrapper>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginLeft: window.innerWidth > 768 ? "10.3vw" : 5,
        }}
      >
        <img
          src={
            // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
            logo
          }
          alt="Description of the image"
          width={window.innerWidth > 768 ? "50px" : "30px"}
          height={"auto"}
        />
        {window.innerWidth > 768 ? (
          <LogoName
            src={FitSilhouetteNameDark}
            alt="Description of the image"
          />
        ) : null}
      </div>
      {window.innerWidth < 768 ? <Title>Fit Silhouette</Title> : null}
      {window.innerWidth > 768 ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingInlineEnd: "150px",
          }}
        >
          <HeaderText
            active={activeText === 1}
            style={{ marginRight: "2.11vw", border: "0" }}
            onClick={() => {
              handleButtonClick(HomeContainer);
              handleHeaderTextClick(1);
            }}
          >
            <span>Home</span>
          </HeaderText>

          <HeaderText
            active={activeText === 2}
            onClick={() => {
              handleButtonClick(Whatwearecontainer);
              handleHeaderTextClick(2);
            }}
            style={{ marginRight: "2.11vw", border: "0" }}
          >
            <span>About</span>
          </HeaderText>
          <HeaderText
            active={activeText === 3}
            onClick={() => {
              handleHeaderTextClick(3);
              handleButtonClick(OurFeaturesContainer);
            }}
            style={{ marginRight: "3.69vw", border: "0" }}
          >
            <span>Features</span>
          </HeaderText>
          <Button
            onClick={login}
          >
            <JoinNowButtonText onClick={()=>{login()}}>JOIN NOW </JoinNowButtonText>
          </Button>
        </div>
      ) : (
        <MenuButton onClick={toggleDrawer}>&#9776;</MenuButton>
      )}
      {window.innerWidth < 768 ? (
        <NavigationDrawer open={drawerOpen}>
          <DrawerContent>
            <HeaderText
              active={activeText === 1}
              onClick={() => {
                handleButtonClick(HomeContainer);
                handleHeaderTextClick(1);
                closeDrawer();
              }}
            >
              <span>Home</span>
            </HeaderText>

            <HeaderText
              active={activeText === 2}
              onClick={() => {
                handleButtonClick(Whatwearecontainer);
                handleHeaderTextClick(2);
                closeDrawer();
              }}
            >
              <span>About</span>
            </HeaderText>

            <HeaderText
              active={activeText === 3}
              onClick={() => {
                handleButtonClick(OurFeaturesContainer);
                handleHeaderTextClick(3);
                closeDrawer();
              }}
            >
              <span>Features</span>
            </HeaderText >
            <div
              style={{
                width: "100%",
                backgroundColor: "#383bcf",
                marginTop: "15px",
              }}
              onClick={login}
            >
              <Text
                style={{ color: "white", textAlign: "left" }}
                // onClick={() => {
                //   login();
                //  closeDrawer();
                // }}
              >
                Join Now
              </Text>
            </div>
          </DrawerContent>
        </NavigationDrawer>
      ) : null}
      {window.innerWidth < 768 ? (
        <Overlay open={drawerOpen} onClick={closeDrawer} />
      ) : null}
    </HeaderWrapper>
  );
}

export default Header;

const HeaderWrapper = styled.header`
  height: 12.5vh;
  background-color: white;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0.7rem; */
  top: 0;
  position: sticky;
  z-index: 3;

  @media (max-width: 768px) {
    height: 100%;
    padding: 5px 10px;
  }
`;

const LogoName = styled.img`
  width: 11.55vw;
  height: auto;
  margin-left: 1.05vw;

  /* @media (max-width: 768px) {
    width: 110px;
    height: 13px;
    margin-top: -30px;
  } */
`;

const NavigationDrawer = styled.div`
  /* Styles for the navigation drawer */
  position: fixed;
  top: 0;
  right: ${({ open }) => (open ? "0" : "-300px")};
  width: 50%;
  height: 100%;
  background-color: white;
  color: #383bcf;
  transition: right 0.3s ease-in-out;
  z-index: 3;
`;

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3); /* Semi-transparent overlay */
  display: ${({ open }) => (open ? "block" : "none")};
  z-index: 2;
`;

const DrawerContent = styled.div`
  /* padding: 20px; */
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
`;

const Title = styled.h1`
  font-size: 1.38vw;
  font-family: "Gilroy-SemiBold", Helvetica;
  color: #050639;
  font-weight: 700;

  @media (max-width: 768px) {
    font-size: 20px;
  }
`;

const Button = styled.button`
  /* margin-right: 10.3vw; */
  align-items: center;
  background-color: #383bcf;
  border-radius: 7737.32px;
  border-color: transparent;
  display: inline-flex;
  justify-content: center;
  padding: 0.7vh 1.58vw;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s; /* Add a transition for the hover effect */

  &:hover {
    transform: scale(1.1);
    /* Grow the button on hover */
  }
`;

const JoinNowButtonText = styled.div`
  color: #ffffff;
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 0.92vw;
  font-weight: 600;
  letter-spacing: 0;
  line-height: normal;
  /* margin-top: -1.55px; */
  position: relative;
  text-align: center;
  width: fit-content;
`;

const HeaderText = styled.div`
  /* color: #383bcf; */
  position: relative;

  /* color: ${(props) => (props.active ? "#383bcf" : "#4F4F4F")}; */
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 1.05vw;
  font-weight: 800;
  height: 2.25vh;
  letter-spacing: 0;
  line-height: normal;
  cursor: pointer;
  text-align: center;

  span {
    color: ${(props) => (props.active ? "#383bcf" : "#4F4F4F")};
  }

  span::after {
    content: "";
    display: ${(props) => (props.active ? "block" : "none")};
    width: 3.69vw;
    height: 3px;
    background-color: blue;
  }
  &:hover {
    transform: scale(1.1);
    /* Grow the button on hover */
  }
  @media (max-width: 768px) {
    color: #383bcf;
    font-weight: 700;
    font-size: 17px;
    margin-left: 20px;
    margin-top: 15px;
    margin-bottom: 15px;
    font-family: Gilroy-Light, Helvetica;
    text-align: center;

    span::after {
      width: 100%;
      height: 2px;
    }
  }
`;

const MenuButton = styled.button`
  background-color: transparent;
  border: none;
  color: #383bcf;
  font-size: 1.8rem;
  cursor: pointer;
`;

const Text = styled.h1`
  font-size: 17px;
  color: #383bcf;
  font-weight: 700;
  cursor: pointer;
  font-family: Gilroy-Light, Helvetica;
  text-align: center;
  margin-left: 20px;
  margin-top: 15px;
  margin-bottom: 15px;
`;
