// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
 const firebaseConfig = {
  apiKey: "AIzaSyCBtBa9rmjdbAPYgKDZyceWsJYz3oPeA68",
  authDomain: "fitsilhouette-feea9.firebaseapp.com",
  projectId: "fitsilhouette-feea9",
  storageBucket: "fitsilhouette-feea9.appspot.com",
  messagingSenderId: "792843117136",
  appId: "1:792843117136:web:94c61c0c8e2dd48f986755",
  measurementId: "G-7ZVYY85CZ1"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth  = getAuth(app);
const provider = new GoogleAuthProvider();
export { auth, provider };
