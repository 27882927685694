import React from "react";
import { styled } from "styled-components";
// import logo from "../../images/logo.webp";

function Refund() {
  return (
    <MainDiv style={{ width: "100%", alignItems: "center" }}>
      {/* <img
        src={
          // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
          logo
        }
        alt="Description of the image"
        height={"90px"}
        width={"90px"}
        // width={"00px"}
        // style={{ marginLeft: 30, marginRight: 10 }}
      /> */}
      <MainDiv
        style={{
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        <HeadingText>Refund & Cancellation / Return policy</HeadingText>
        <Description>Effective Date:september 9,2023</Description>

        <HeadingText>Subscription Refunds:</HeadingText>
        <Description>
          We value your commitment to using “Fit Silhouette” and understand that
          circumstances may change. However, we regret to inform you that we do
          not offer refunds for subscription cancellations. Whether you have a
          monthly or annual subscription, once you have made the payment, it is
          non-refundable. You will continue to have access to the premium
          features and content until the end of your current subscription
          period.
        </Description>

        <HeadingText>Product Refunds:</HeadingText>
        <Description>
          At Fit Silhouette, we aim to provide you with the best fitness
          products to enhance your fitness journey. If you are not satisfied
          with a product purchased through our application, we offer a refund
          policy with the following conditions:
          <br />• Return Request: To be eligible for a product refund, you must
          submit a return request through our customer support within 30 days of
          receiving the product. You can contact our customer support team at
          support@fitsilhouette.com
          <br />• Product Condition: The product must be in its original
          packaging, unused, and in the same condition as when you received it.
          <br />• Proof of Purchase: You must provide a valid proof of purchase,
          such as an order number or a purchase receipt, when requesting a
          product refund.
          <br />• Return Shipping: You are responsible for the cost of shipping
          the product back to us.
          <br />• Refund Processing: Once we receive the returned product and
          inspect it to ensure it meets our refund criteria, we will initiate
          the refund process. Please allow up to 15 business days for the refund
          to reflect in your original payment method. The timing of the refund
          may vary depending on your financial institution.
        </Description>

        <HeadingText>Contact Us:</HeadingText>
        <Description>
          If you have any questions or concerns about our refund policy, please
          contact our customer support team at support@fitsilhouette.com We are
          here to assist you and ensure your experience with “Fit Silhouette” is
          positive and satisfactory. Please note that this refund policy is
          subject to change, and any updates will be posted on our website and
          communicated to users as necessary.
          <br />
          <br />
          Thank you for choosing “Fit Silhouette” to support your fitness
          journey. Your satisfaction is important to us, and we appreciate your
          understanding of our refund policy.
        </Description>

        <HeadingText>Important Note:</HeadingText>
        <Description>
          Our Refund and delivery policies comply with the regulations and
          guidelines mandated by the Reserve Bank of India (RBI). We adhere to
          these rules during our verification and Refund processes to ensure a
          secure and compliant experience for our customers.
          <br />
          <br />
          Thank you for choosing Fit Silhouette. We value your trust and are
          dedicated to providing you with a seamless shopping experience. If you
          have any further inquiries or require assistance, please do not
          hesitate to contact our customer support team.
          <br />
          Please note that this Refund & Delivery Policy is subject to change,
          and any updates will be posted on our website and communicated to
          users as necessary
        </Description>
      </MainDiv>
    </MainDiv>
  );
}

export default Refund;

const MainDiv = styled.div`
  align-self: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const HeadingText = styled.h1`
  font-size: 20px;
  color: black;

  font-family: Gilroy-Regular, Helvetica;
  font-weight: 700;
  margin-top: 40px;
`;
const Description = styled.h1`
  font-size: 17px;

  font-family: Gilroy-Regular, Helvetica;
  color: black;
  font-weight: 500;
  margin-top: 5px;
`;
