import React from "react";
import { signInWithPopup } from "firebase/auth";
import { styled } from "styled-components";
import { useNavigate } from "react-router-dom";
import {auth ,provider} from  "./firebaseConfig"
function JoinNowScreen() {
  const navigate = useNavigate();
  // const [value ,setValue] =useState('');
  const login = () => {
    console.log("kmckdcmdlc");
    signInWithPopup(auth, provider).then((data) => {
    //  setValue(data.user.email);
      localStorage.setItem("email", data.user.email);
      navigate("/paymentspage");
    });
  };

  return (
    <ColumnDiv>
      {window.innerWidth > 768 ? (
        <Text>
          <Normal>Join </Normal>
          <Bigger>“Fit Silhouette” </Bigger>
          <Normal>today.</Normal>
        </Text>
      ) : (
        <Text>
          <Normal>Join</Normal>
          <Bigger>
            <br />
            “Fit Silhouette”
            <br />
          </Bigger>
          <Normal>today.</Normal>
        </Text>
      )}

      <LearnMoreButton onClick={() => login()}>
        <ButtonText>Start Your Journey</ButtonText>
      </LearnMoreButton>
      {/* <Slogan
        style={{
          fontSize: window.innerWidth > 768 ? 18 : 14,
        }}
      >
        3 Day Free Trial
      </Slogan> */}
    </ColumnDiv>
  );
}

export default JoinNowScreen;

const ColumnDiv = styled.div`
  width: 100vw;
  height: auto;
  //  background-color: #e7dbdb;
  background-color: #f6f6f6;
  margin-top: 9.84vh;
  padding-top: 8vh;

  @media (max-width: 768px) {
    padding-top: 40px;
    margin-top: 50px;
    height: 100%;
  }
  /* padding-bottom: 20px; */
  /* margin-bottom: 100px; */
`;
const LearnMoreButton = styled.button`
  all: unset;
  align-items: center;
  background-color: #383bcf;
  border: 2px solid;
  border-color: #383bcf;
  border-radius: 9999px;
  box-sizing: border-box;
  display: inline-flex;
  gap: 10px;
  justify-content: center;
  padding: 0.8vh 3.69vw;
  position: relative;
  cursor: pointer;
  margin-top: 5vh;
  margin-bottom: 8vh;

  @media (max-width: 768px) {
    padding: 5px 50px;
    margin-top: 20px;
    margin-bottom: 40px;
    /* width: 80%; */
  }
`;

const ButtonText = styled.div`
  color: #ffffff;
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 1.18vw;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 3.37vh; */
  /* margin-top: -2px; */
  position: relative;
  text-align: right;
  white-space: nowrap;
  /* width: fit-content; */

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

const Text = styled.div`
  color: #333333;
  font-family: Gilroy-Medium, Helvetica;
  font-size: 3.17vw;
  font-weight: 400;
  /* height: 8.29vh; */
  letter-spacing: 0;
  line-height: normal;
  text-align: center;
  // if mobile
  @media (max-width: 768px) {
    font-size: 30px;
  }
`;

const Normal = styled.span`
  font-weight: 500;
`;
const Bigger = styled.span`
  font-family: Gilroy-SemiBold, Helvetica;
  font-weight: 800;

  @media (max-width: 768px) {
    font-family: Gilroy-Bold, Helvetica;
  }
`;
