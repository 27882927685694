import React from "react";
import styled from "styled-components";
import homescreenmobile from "../images/homescreen.png";
import homescreen from "../images/homescreen1.png";
// import logo from "../images/Fit Silhouette Logo.png";
import { useNavigate } from "react-router-dom";
import FitsilhouetteWithShadow from "../images/Fit silhouetteWithShadow.png";
import { auth, provider } from "./firebaseConfig";
// import Shadow from "../images/logoshadow.png";
// import FitSilhouetteName from "../images/FitSilhouetteName.png";
import { signInWithPopup } from "firebase/auth";
function Home({ container, whatarewecontainer }) {
  const navigate = useNavigate();
  
  const login = () => {
    console.log("kmckdcmdlc");
    signInWithPopup(auth, provider).then((data) => {
     // setValue(data.user.email);
      localStorage.setItem("email", data.user.email);
      navigate("/paymentspage");
    });
  };

  return (
    <ColumnDiv ref={container}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          // backgroundColor: "black",
        }}
      >
        <ImageDiv>
          <SloganDiv>
            {/* <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ShadowImage>
              <Logo>
                <img
                  src={
                    // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
                    logo
                  }
                  height={"100%"}
                  width={"100%"}
                  alt="Description of the image"
                  // width={"138px"}
                />
              </Logo>
            </ShadowImage>
            <LogoName>
              <img
                src={
                  // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
                  FitSilhouetteName
                }
                alt="Description of the image"
                height={"100%"}
                width={"100%"}
                // height={"auto"}
                // // width={"13vw"}
                // // width={"00px"}
                // style={{
                //   marginLeft: window.innerWidth > 768 ? 30 : 5,
                //   marginRight: 10,
                //   marginTop: "-1.54vh",
                // }}
              />
            </LogoName>
          </div> */}
            <LogoName>
              <img
                src={FitsilhouetteWithShadow}
                width={"100%"}
                height={"100%"}
              />
            </LogoName>
            <Slogan
              style={{
                marginTop: window.innerWidth > 768 ? "2.11vh" : "15px",
              }}
            >
              Where Passion
              <br />
              Meets Performance.
            </Slogan>
            <div>
              <LearnMoreButton onClick={()=>login()}>
                <LearnMoreButtonText>Start Your Journey</LearnMoreButtonText>
              </LearnMoreButton>
              <Slogan
                style={{
                  fontSize: window.innerWidth > 768 ? "0.79vw" : 11,
                  fontFamily: "Gilroy-SemiBold, Helvetica",
                  fontWeight: "500",
                  marginTop: "0.79vw",
                  textAlign: "center",
                }}
              >
                3 days free-trial (Limited Offer)
              </Slogan>
            </div>
          </SloganDiv>
        </ImageDiv>
        {window.innerWidth > 768 ? (
          <RowDivCard>
            <CardDivs>
              <CardDivText1>15000+</CardDivText1>
              <CardDivText2>Meal Plans</CardDivText2>
            </CardDivs>
            <CardDivs>
              <CardDivText1>1000+</CardDivText1>
              <CardDivText2>Workouts</CardDivText2>
            </CardDivs>
            <CardDivs>
              <CardDivText1>40 Million +</CardDivText1>
              <CardDivText2>Food Database</CardDivText2>
            </CardDivs>
            <CardDivs>
              <CardDivText1>10 Million +</CardDivText1>
              <CardDivText2>Downloads</CardDivText2>
            </CardDivs>
          </RowDivCard>
        ) : null}
      </div>
      <div ref={whatarewecontainer}>
        <StartJouneyDiv>
          <StartJourneyText>
            The Journey to be a Better You Starts Here.
          </StartJourneyText>

          <StartJourneyText2>
            Designed and Crafted for those who are ready to make fitness a
            consistent habit in their lives.
          </StartJourneyText2>
          <LearnMoreButton
            style={{
              backgroundColor: "#383bcf",
              padding: window.innerWidth > 768 ? "0.8vh 3.69vw" : "7px 40px",
              marginTop: window.innerWidth > 768 ? "7.87vh" : "25px",
              marginBottom: window.innerWidth > 768 ? "11.8vh" : "20px",
            }}
          >
            <LearnMoreButtonText style={{ color: "white", fontWeight: "500" }}>
              Start Your Journey
            </LearnMoreButtonText>
          </LearnMoreButton>
        </StartJouneyDiv>
      </div>
    </ColumnDiv>
  );
}

export default Home;

const ColumnDiv = styled.div`
  /* min-height: 90vh; */
  /* width: 100%; */
  /* width: 100vw; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  // if mobile
  @media (max-width: 768px) {
    flex-direction: column;
  }
  /* height: 50px; */
`;

// const ShadowImage = styled.div`
//   background-image: url(${Shadow});
//   background-size: 100% auto;
//   object-fit: cover;
//   background-repeat: no-repeat;
//   image-rendering: auto;
//   width: 12.18vw;
//   height: 12.18vw;
//   display: flex;
//   align-items: center;
//   justify-content: center;

//   @media (max-width: 768px) {
//     width: 130px;
//     height: 130px;
//   }
// `;

// const Logo = styled.div`
//   background-size: 100% 100%;
//   width: 4.75vw;
//   height: auto;

//   @media (max-width: 768px) {
//     width: 70px;
//     height: 70px;
//   }
// `;
const LogoName = styled.div`
  background-size: 100% 100%;
  width: 13.01vw;
  height: auto;
  margin-top: -4.09vh;

  @media (max-width: 768px) {
    width: 110px;
    height: auto;
    margin-top: -30px;
  }
`;

const CardDivs = styled.div`
  align-items: center;
  background-color: transparent;
  display: inline-flex;
  flex-direction: column;
  gap: 14px;
  padding: 6.18vh 6.2vw;
  position: relative;

  @media (max-width: 768px) {
    width: 80vw;
    padding: 30px 0px;
    margin-bottom: 20px;
  }
`;

const CardDivText1 = styled.div`
  color: #333333;
  font-family: Gilroy-ExtraBold, Helvetica;
  font-size: 1.58vw;
  font-weight: 800;
  line-height: normal;
  height: 4.07vh;
  /* letter-spacing: 0.02ch; */
  margin-top: -1px;
  position: relative;
  text-align: center;
  /* width: fit-content; */

  @media (max-width: 768px) {
    font-size: 22px;
  }
`;
const CardDivText2 = styled.div`
  color: #555547;
  font-family: Gilroy-Light, Helvetica;
  font-size: 1.18vw;
  font-weight: 400;
  letter-spacing: 0;
  line-height: normal;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;

  @media (max-width: 768px) {
    font-size: 17px;
  }
`;

// Start Jouney Div

const StartJouneyDiv = styled.div`
  margin-top: 9vh;
  /* align-self: center; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const StartJourneyText = styled.div`
  color: #333333;
  font-family: Gilroy-SemiBold, Helvetica;
  font-size: 2.11vw;
  font-weight: 700;
  /* line-height: 1.23; */
  letter-spacing: 0;
  height: 5.48vh;
  letter-spacing: 0;
  line-height: normal;
  text-align: center;

  @media (max-width: 768px) {
    height: 100%;
    font-size: 25px;
    width: 80vw;
    /* margin-bottom: 20px; */
  }
`;

const StartJourneyText2 = styled.div`
  color: #4f4f4f;
  margin-top: 2.8vh;
  font-family: Gilroy-Light, Helvetica;
  font-size: 1.58vw;
  font-weight: 400;
  height: 60px;
  letter-spacing: 0;
  line-height: 4.2vh;
  /* position: fixed; */
  text-align: center;
  width: 41.1vw;

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 18px;
    width: 80%;
    height: 100%;
    line-height: normal;
  }
`;

const RowDivCard = styled.div`
  /* min-height: 90vh; */
  /* width: 100%; */
  /* width: 100vw; */
  display: flex;
  align-self: center;
  margin-top: -8.55vh;
  flex-direction: row;
  border-radius: 12px;
  box-shadow: 0px 2px 2px rgba(68, 67, 67, 0.2);
  justify-content: space-evenly;
  align-items: center;
  background-color: #f6f6f6;
  z-index: 1;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageDiv = styled.div`
  /* flex: 0 0 36%; */
  flex: 1;
  background-image: url(${homescreen});
  background-size: 100% auto;
  object-fit: cover;
  background-repeat: no-repeat;
  image-rendering: auto;
  image-resolution: initial;
  width: 100vw;
  height: auto;
  padding-bottom: 21.9vh;
  /* width: 100vw;
  height: 100%; */
  position: relative;
  display: flex;
  justify-content: end;
  align-items: end;

  // if mobile
  @media (max-width: 768px) {
    background-image: url(${homescreenmobile});
    padding-bottom: 30px;
    height: 100%;
    align-items: start;
    padding-top: 30vh;
    background-color: #383bcf;
    margin-left: 10px;
    margin-right: 10px;
    //    height: 60vh fit-content;
  }
`;

const SloganDiv = styled.div`
  margin-right: 9.77vw;
  margin-top: 21.94vh;
  /* margin-bottom: 10.36vh; */
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;

  // if mobile
  @media (max-width: 768px) {
    width: 90vw;
    margin-top: 30px;
    /* margin-bottom: 30px; */
  }
`;

const Slogan = styled.div`
  font-size: 3.17vw;
  font-weight: 800;
  letter-spacing: 0;
  line-height: normal;
  /* letter-spacing: 0.02ch; */
  text-align: right;
  margin-top: 32px;
  margin-bottom: 32px;
  color: white;
  font-family: Gilroy-SemiBold, Helvetica;

  // if mobile
  @media (max-width: 768px) {
    font-size: 30px;
    margin-bottom: 22px;
  }
`;
// const BoldSlogan = styled.span`
//   /* font-weight: 800; */
//   font-size: 47px;
//   font-family: "Gilroy", sans-serif;

//   // if mobile
//   @media (max-width: 768px) {
//     font-size: 25px;
//   }
// `;

const LearnMoreButtonText = styled.div`
  color: #383bcf;
  font-family: "Gilroy-SemiBold", Helvetica;
  font-size: 1.18vw;
  font-weight: 600;
  letter-spacing: 0;
  /* line-height: 3.37vh; */
  /* margin-top: -2px; */
  letter-spacing: 0;
  /* position: relative; */
  text-align: right;
  white-space: nowrap;
  /* width: fit-content; */

  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 0px;
  }
`;

const LearnMoreButton = styled.button`
  background-color: white;
  color: #383bcf;
  /* width: 80%; */
  /* margin-top: 40px; */
  /* border: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid;
  border-color: #ffffff;
  border-radius: 9999px; /* Make it round */
  /* font-size: 1rem; */
  cursor: pointer;
  gap: 10px;
  padding: 0.4vh 3.69vw;
  transition: transform 0.2s; /* Add a transition for the hover effect */

  &:hover {
    transform: scale(1.1);
    /* background-color: #030382; */
    color: white;

    /* Grow the button on hover */
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
    width: auto;
    padding: 3px 40px;
  }
`;
