import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Home from "./Home";
import OurFeatures from "./OurFeatures";
import Whatarewe from "./Whatarewedivs/Whatarewe";
import PaymentPlans from "./payments";
import {auth ,provider} from  "./firebaseConfig"
const HomeScreen = ({
  HomeContainer,
  Whatwearecontainer,
  OurFeaturesContainer,
}) => {
  const location = useLocation();

  const [scroller, setScroller] = useState(location.state?.scrollTo || null);

  useEffect(() => {
    if (scroller) {
      if (scroller == "HomeContainer") {
        HomeContainer.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else if (scroller == "Whatwearecontainer") {
        Whatwearecontainer.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      } else if (scroller == "OurFeaturesContainer") {
        OurFeaturesContainer.current.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }

      setScroller(null);
    }
  }, [scroller]);

  return (
    <div
      style={
        {
          // overflow: "hidden",
          // backgroundColor: "#130242",
        }
      }
    >
      {/* <Header
        whatarewe={Whatwearecontainer}
        ourfeatures={OurFeaturesContainer}
        download={DownloadContainer}
      /> */}
      {/* <h1>{`${location.state?.scrollTo}`}</h1> */}
      <Home container={HomeContainer} whatarewecontainer={Whatwearecontainer} />
      <Whatarewe container={Whatwearecontainer} />
      <OurFeatures container={OurFeaturesContainer} />
       <PaymentPlans container ={PaymentPlans}/>
      {/* <Footer /> */}
      {/* <DownloadApp container={DownloadContainer} /> */}

      {/* <Footer /> */}
      {/* <Home
      imageUrl={
        "https://cdn.dribbble.com/users/2025435/screenshots/9939701/my_fitness_2.webp"
      }
    /> */}
    </div>
  );
};

export default HomeScreen;
