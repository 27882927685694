import React from "react";
import { styled } from "styled-components";
// import logo from "../../images/logo.webp";

function Terms() {
  return (
    <MainDiv style={{ width: "100%", alignItems: "center" }}>
      {/* <img
        src={
          // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
          logo
        }
        alt="Description of the image"
        height={"90px"}
        width={"90px"}
        // width={"00px"}
        // style={{ marginLeft: 30, marginRight: 10 }}
      /> */}
      <MainDiv
        style={{
          width: window.innerWidth > 768 ? "70%" : "95%",
        }}
      >
        <HeadingText>
          TERMS AND CONDITIONS
          <br />
          <br />
          Terms Of Use
          <br />
          <br />
          WELCOME TO FIT SILHOUETTE <br />
          <br />
          Legal Agreement
          <br />
        </HeadingText>

        <Description>
          This User Agreement ("Agreement") is a legally binding agreement
          between you and Fit Silhouette Wellness Products And Services Private
          Limited ("Fit Silhouette" or "we" or "us" as the context requires)
          governing your use of Fit Silhouette's products, software, and/or
          services with the characteristics and features as described on
          www.fitsilhouette.com ("Website") and the Fit Silhouette mobile App
          ("App") and your use of the Move Better marketplace made available on
          the Website and App ("Marketplace") (referred to collectively as the
          "Services"). Fit Silhouette may have subsidiaries and affiliated legal
          entities around the world ("Subsidiaries and Affiliates"), providing
          the Services to you on behalf of Fit Silhouette. You acknowledge and
          agree that the Subsidiaries and Affiliates will be entitled to provide
          the Services to you under the terms of this Agreement.
          <br />
          <br />
          By accessing the Website, the App, or the Marketplace, at your option,
          registering thereon, and thereafter using the Services as a member or
          guest, you agree to be bound by this Agreement and the terms contained
          in it. This Agreement governs your access and use of this
          Website/App/Marketplace and applies to all visitors, users, and others
          who access the Service ("Users"). If you do not agree with the terms
          contained in this Agreement, you are not permitted to use this
          Website/App/Marketplace. Fit Silhouette will not be liable for any
          consequences arising from your unauthorised use. We may revise these
          terms of use at any time by amending this page and the terms hereof.
          The revised terms of use shall be posted on the Website/App, and you
          are expected to check this page from time to time to take notice of
          any changes we make, as they are binding on you. Some of these
          provisions may be superseded by provisions or notices published
          elsewhere on our Website/App/Marketplace. All changes are effective as
          soon as we post them, and by continuing to use the Website/App/
          Marketplace and avail of the Services, you agree to be bound by the
          revised terms and conditions of use. Your use of
          Website/App/Marketplace is subject to the most current version of the
          terms of use posted on the Website/App/Marketplace at the time of such
          use.
          <br />
          <br />
          Fit Silhouette has created this Website/App/Marketplace to provide
          Users with Services that offer guidance on how to enhance their
          fitness levels and seamlessly integrate fitness into their unique
          lifestyles. Additionally, Fit Silhouette provides Users access to a
          wide array of healthy and nutritious food products through its
          Marketplace. While our workout and nutrition recommendations take into
          account several factors specific to each individual, such as
          anthropometric data, fitness goals, and lifestyle factors, it is
          essential to note that Fit Silhouette is not a medical organisation.
          Therefore, our recommended workout plans and specific exercises should
          not be interpreted as medical advice, prescriptions, or diagnoses. The
          Marketplace is an e-commerce platform accessible on the Website and
          App, offering Users access to a diverse range of health and
          nutrition-based products ("Products"). These Products are listed for
          sale by various vendors on the Marketplace. Users have the option to
          place orders and purchase the Products through the payment gateway
          provided therein. The use of the Marketplace and the purchase of
          Products shall be governed by the terms outlined in this Agreement.
          <br />
          <br />
          ACCESS
          <br />
          Access to the Services provided by Fit Silhouette is granted on a
          temporary basis, and we reserve the right to withdraw or modify the
          Service (or any features within the Services) provided on our
          Website/App/Marketplace by notifying you through our
          Website/App/Marketplace from time to time without prior notice. We
          shall not be liable if, for any reason, our Website/App/Marketplace or
          the Services (or any features within the Services) become unavailable
          at any time or for any period. Periodically, we may, at our sole
          discretion, limit access to certain parts of our
          Website/App/Marketplace, or the entire Website/App/Marketplace, to
          Users who have registered with us.
          <br />
          Fit Silhouette grants you permission to use the Services as outlined
          in this Agreement, on the condition that: (i) you shall not copy or
          distribute any part of the Services in any medium or manner whatsoever
          without Fit Silhouette's explicit authorisation; (ii) you shall not
          alter or modify any part of the Services except as reasonably
          necessary to use the Services for their intended purpose; and (iii)
          you shall otherwise comply with the terms and conditions of this
          Agreement.
          <br />
          By using the Services and completing the registration process, you
          warrant that: (a) all data provided by you is accurate and complete;
          (b) you will maintain the accuracy of such information and update any
          changes promptly; (c) you affirm that you are over 18 (eighteen) years
          of age and are fully capable of entering into the terms, conditions,
          obligations, affirmations, representations, and warranties set forth
          in this Agreement and will abide by and comply with this Agreement;
          (d) you are eligible in accordance with applicable laws to enter into
          a binding contract and are not barred from receiving the Services
          under applicable laws; and (e) you will use the Website/App or any
          other Services provided through the Website/App only in compliance
          with this Agreement and all applicable local, state, national, and
          international laws, rules, and regulations. We shall not be liable for
          any injury, damage, or other consequence, health-related or otherwise,
          arising from any inaccuracy in the information provided by you on the
          Website/App. We may delete your profile without warning or notice if
          we believe that you have violated any of the conditions mentioned
          under this Agreement or the Privacy Policy.
          <br />
          To complete the registration process and be entitled to use the
          Services, you must provide your full legal name, valid email address,
          and any other information requested from you from time to time. The
          information you provide may be shared by us with any third party for
          providing the Services, record-keeping purposes, internal procedures,
          or any other purposes. By using this Website/App, you expressly
          consent to such sharing of the information provided by you.
          <br />
          After completing the registration process, you will receive a user
          identification code, password, or any other piece of information as
          part of our security procedures. You must treat such information as
          confidential and not disclose it to any third party. You also agree to
          log out from your account at the end of each session. We have the
          right to disable any user identification code or password, whether
          chosen by you or allocated by us, at any time if, in our opinion, you
          have failed to comply with any provisions of this Agreement. You must
          immediately notify Fit Silhouette of any unauthorised use of your
          password or user identification code by sending details of such
          unauthorised use to: help@fitsilhouette.com.
          <br />
          You are responsible for making all arrangements necessary to have
          access to our Website/App/Marketplace and thereby the Services. You
          are also responsible for ensuring that anyone accessing our
          Website/App/Marketplace and thereby the Services through your account
          is aware of these terms and complies with them. You are solely
          responsible and liable to Fit Silhouette for all activities that take
          place or occur under your account. You agree that your ability to log
          into your account is dependent upon external factors such as internet
          service providers and internet network connectivity, and we shall not
          be liable to you for any damages arising from your inability to log
          into your account.
          <br />
          Fit Silhouette reserves the right to refuse access to the Services
          offered on the Website/ App/Marketplace to new Users or to terminate
          access granted to existing Users at any time without providing reasons
          for doing so. The use of the account is not available to any User who
          is suspended or prohibited by Fit Silhouette from using the
          Website/App/ Marketplace or Services for any reason whatsoever
          <br />
          MEMBERSHIP/REGISTRATION FOR THE SERVICES
          <br />
          Once you complete registration on the Website/App, you will become a
          member, which:
          <br />• allows you to access and post content on the
          Website/App/Marketplace;
          <br />• allows us to contact you, if necessary, to inform you of
          changes to the terms of use of the Website/App or describe new
          services available on the Website/App;
          <br />• allows you to access and purchase Products on the Marketplace,
          subject to the terms and conditions set out herein;
          <br />• allows you to make use of the Services on the Website/App,
          subject to these terms and conditions;
          <br />
          does not allow you to use email addresses displayed on the Website/App
          for any purpose other than that specifically authorised by us. By
          providing Fit Silhouette your email address/phone number, you consent
          to:
          <br />• our using your email address or mobile number to send you
          Service-related notices, including any notices required by law,
          instead of communication by postal mail. You may use your settings to
          opt out of Service-related communications via email/mobile number or
          entirely.
          <br />• our using the phone numbers provided by you to contact you
          from time to time to provide you updates and advice regarding your
          progress on the App and the usage of our Services, as well as
          information about any new promotions and sales opportunities on the
          App.
          <br />• our using your email address, home address, phone number, and
          other ancillary information to fulfil the orders placed by you on the
          Marketplace.
          <br />• our using your email address or phone number to send you other
          messages, including changes to features of the Service and special
          offers. If you do not want to receive such email messages, you may
          opt-out by sending us an email at: info@fitsilhouette.com. Opting out
          may prevent you from receiving email messages or phone messages
          regarding updates, improvements, or offers.
          <br />• Grant such permission to contact you through telephone, SMS,
          email, and holds Fit Silhouette (including but not limited to its
          subsidiaries/affiliates) indemnified against any liabilities,
          including financial penalties, damages, expenses, in case your mobile
          number is registered with the Do not Call (DNC) database.
          <br />
          <br />
          GRANT OF LICENSE
          <br />
          <br />
          Subject to these terms and conditions of use, Fit Silhouette grants
          you a nontransferable, non-exclusive, non-sub-licensable limited right
          and license for you to access and use the Services solely for the
          purpose permitted, including but not limited to storing or otherwise
          using or sharing your documents, files, schedules, minutes, or other
          data.
          <br />
          PRIVACY AND YOUR PERSONAL INFORMATION For information about Fit
          Silhouette's data protection practices, please read Fit Silhouette's
          privacy policy set out below. This policy explains how Fit Silhouette
          treats your personal information and protects your privacy when you
          use the Services. TERMS OF USE OF THE MARKETPLACE You may access and
          use the Marketplace to purchase Products listed thereon by thirdparty
          vendors from time to time. Fit Silhouette's responsibility in terms of
          the Marketplace shall extend solely and exclusively to: The provision
          of the platform for the Marketplace on the Website/App; Collection of
          payments from Users using the third-party payment gateway on the
          Marketplace. In the event of any disputed payments made, Fit
          Silhouette shall pass on the dispute to the payment gateway provider
          for resolution. This shall be the extent of Fit Silhouette's
          obligations in this regard; Delivery of the Products to the Users who
          have placed orders therefor on the Marketplace. It is hereby clarified
          that the Products listed on the Marketplace are listed by third-party
          vendors. The Products do not belong to Fit Silhouette in any manner
          whatsoever. Thus Fit Silhouette makes no warranty for, and shall not
          be responsible in any manner whatsoever for the quality, quantity, or
          authenticity of the Products. There shall be no refunds or
          cancellations initiated or entertained once an order is placed and
          processed on the Marketplace, save as set out herein.
          <br />
          <br />
          UPLOADING MATERIAL TO THE SERVICE
          <br />
          <br />
          Whenever you make use of the Services (or any feature or part of the
          Services) that allows you to upload material on the
          Website/App/Marketplace, or to make contact with other users of our
          Website/App or vendors on the Marketplace, you must comply with
          applicable data protection rules/laws and the content standards set
          out below. The content standards apply to each part of a contribution
          as well as to its whole. Your contribution must be accurate where it
          states facts, be genuinely held (where it states opinions), comply
          with the law applicable in any country from which it is posted, and be
          relevant to your posting or use of the Services. Restrictions on
          Content Your contribution must not: belong to another person and
          contain any information that you do not have any right to; Be
          defamatory of any person; Be obscene, offensive, pornographic,
          hateful, harmful, harassing, defamatory, vulgar, libellous, lewd,
          blasphemous, lascivious, invasive of another’s privacy, racially,
          ethnically, or otherwise objectionable or inflammatory, disparaging,
          relating or encouraging money laundering or gambling, or otherwise
          unlawful in any manner whatsoever; Violate the provisions of the
          Indian Penal Code, 1861, the Information Technology Act, 2000,
          Information Technology (Reasonable Security Practices and Procedures
          and Sensitive Personal Data or Information) Rules, 2011, notified vide
          Notification G.S.R 313(E) issued by the Ministry of Communications and
          Information Technology, or other applicable laws containing offences
          relating to content that is publicly accessible;
          <br />
          Promote discrimination based on race, sex, religion, nationality,
          disability, sexual orientation, or age;
          <br />
          Disclose the name, address, telephone, mobile or fax number, e-mail
          address, or any other personal data in respect of any individual;
          <br />
          Be harmful to minors;
          <br />
          Infringe any intellectual property, including copyright, database
          right, or trademark of any other person;
          <br />
          Breach any legal duty owed to a third party, such as a contractual
          duty or a duty of confidence;
          <br />
          Be likely to harass, upset, embarrass, alarm, or annoy any other
          person;
          <br />
          Impersonate any person or misrepresent your identity or affiliation
          with any person;
          <br />
          Advocate, promote, incite any third party to commit or assist any
          unlawful or criminal act;
          <br />
          Contain a statement which you know or believe or have reasonable
          grounds for believing that members of the public to whom the statement
          is, or is to be, published are likely to understand as a direct or
          indirect encouragement or other inducement to the commission,
          preparation, or instigation of acts of terrorism;
          <br />
          Be such that it is known by you to be false, inaccurate, or
          misleading;
          <br />
          Deceive or mislead the addressee about the origin of such messages or
          communicate any information which is grossly offensive or menacing in
          nature;
          <br />
          Be content for which you were compensated or granted any consideration
          by any third party;
          <br />
          Contain any computer viruses, any code, worms, or other potentially
          damaging or limiting the functionality of any computer programs or
          files. Threaten the unity, integrity, sovereignty of India, friendly
          relations with foreign states or public order or cause incitement to
          the commission of any cognizable offence or is insulting to another
          nation. We hereby reserve the right to take down any content posted by
          you, which, in our sole discretion falls under the above restrictions.
          <br />
          <br />
          RESTRICTION ON USE OF SERVICES
          <br />
          <br />
          You are not permitted to and shall not engage in any of the following
          activities, the breach of which shall entitle Fit Silhouette to
          terminate your use of the Services, without prejudice to any other
          rights or remedies we may have against you:
          <br />• Distribute, sell, supply, modify, adapt, amend, incorporate,
          merge, or otherwise alter the Services and all content provided to you
          as part of the Services.
          <br />• Attempt to decompile, reverse engineer, or otherwise
          disassemble any Service or content provided to you as part of the
          Services or the Website or the App or the Marketplace.
          <br />• Attempt to copy any software provided to you as part of the
          Services or the Website or the App or the Marketplace, including its
          source code, or attempt to decrypt any part of such software that is
          provided to you.
          <br />• Create any derivative work or version of any software provided
          by us in relation to or to facilitate your use of the Services or any
          content provided as part of the Services.
          <br />• Remove, from the Services or any content provided as part of
          the Services, any logo, copyright, or proprietary notices, legends,
          symbols, labels, watermarks, signatures, or any other like marks
          affixed to or embedded in the Services. Use the Services or any part
          of it to create a false identity, to impersonate any person or
          organisation, or attempt to disguise the origin of any content.
          <br />• Use any of Fit Silhouette's domain names as a pseudonymous
          return email address.
          <br />• Access or use the Website/App/Marketplace in any manner that
          could damage, disable, overburden, or impair any of the
          Website/App/Marketplace’s servers or the networks connected to any of
          the servers on which the Website/ App/Marketplace is hosted.
          <br />• Access or attempt to access any content that you are not
          authorised to access by any means.
          <br />• Access the Website/App/Marketplace through any other means
          other than through the interface that is provided by us.
          <br />• Alter or modify any part of the Services.
          <br />• Disrupt or interfere with the security of, or otherwise cause
          harm to the Website/App, materials, system resources, or gain
          unauthorised access to the user accounts, passwords, servers, or
          networks connected to or accessible through the
          Website/App/Marketplace or any affiliated linked sites.
          <br />
          <br />
          RIGHTS RESERVED BY FIT SILHOUETTE
          <br />
          <br />
          We have the right to disclose your identity to any third party who
          validly claims and provides evidence that any material posted or
          uploaded by you to our Website/App/ Marketplace constitutes a
          violation of their intellectual property rights or their right to
          privacy. We are not responsible, or liable to any third party, for the
          content or accuracy of any materials posted by you or any other User
          of the Services. We have the right to immediately terminate your
          access or usage rights and remove non-compliant information or
          material in case of non-compliance with these terms and conditions,
          this Agreement, or the Privacy Policy. We have the right to
          investigate and prosecute violations of these terms and conditions to
          the fullest extent of the law and may involve and cooperate with law
          enforcement authorities in prosecuting Users who violate these terms
          and conditions. While we have no obligation to monitor your access to
          or your use of the Services (or any feature or part of the Services),
          we have the right to do so for the purpose of operating the
          Website/App/Marketplace and providing the Services, to ensure your
          compliance with these terms and conditions, or to comply with
          applicable law or the order or requirement of a court, administrative
          agency, or other governmental body. Subject to the receipt of a
          complaint from a User, we have the right (but shall have no
          obligation) to pre-screen, review, flag, filter, modify, refuse, or
          remove any or all content from any Service. For some of the Services,
          Fit Silhouette may (but shall be under no obligation to) provide tools
          to filter out explicit sexual content or any content that it deems
          unsuitable at its sole discretion. We reserve the right to access,
          read, preserve, and disclose any information as we reasonably believe
          is necessary to: (a) satisfy any applicable law, regulation, legal
          process, or governmental request; (b) enforce these terms and
          conditions, including the investigation of potential violations
          hereof; (c) detect, prevent, or otherwise address fraud, security or
          technical issues; (d) respond to user support requests; or (e) protect
          the rights, property, or safety of Fit Silhouette, its Users, and the
          public. Fit Silhouette shall not be responsible or liable for the
          exercise or non-exercise of its rights under these terms and
          conditions in this regard.We reserve the right to introduce or change
          the prices of all Services upon 30 (thirty) days' notice from us. Such
          notice may be provided at any time by posting the changes to the terms
          and conditions of use or the Services on the Website/App itself. Fit
          Silhouette shall not be liable to you or any third party for any
          modification, price change, suspension, or discontinuance of the
          Services. BREACH OF CONTENT STANDARDS When we consider that a breach
          of the content standards (as stated above) has occurred, we may, at
          our discretion, take such action as we deem appropriate. Failure to
          comply constitutes a material breach of the terms of use on which you
          are permitted to use the Service, and we may take any legally
          available action, including all or any of the following:Immediate,
          temporary, or permanent withdrawal of your right to use the Services
          and simultaneous termination/suspension of your account on the
          Website/App.
          <br />• Immediate, temporary, or permanent removal of any contribution
          already posted on the Website/App/Marketplace using the Services, upon
          your failure to cure the breach that is brought to your notice.
          <br />• Issue of a warning to you.
          <br />• Legal proceedings against you for reimbursement of all costs
          on an indemnity basis (including, but not limited to, reasonable
          administrative and legal costs) resulting from the breach.
          <br />• Further legal action against you.
          <br />• Disclosure of such information to law enforcement authorities
          as we reasonably feel is necessary or as required by law.
          <br />
          We hereby disclaim and exclude our liability for all actions we may
          take in response to breaches of these rules. The actions described
          above are not limited, and we may take any other action we reasonably
          deem appropriate. Upon such termination/suspension of your account,
          you will no longer be able to access your account or any content or
          data you have stored on the servers. All licenses granted to you by
          Fit Silhouette to the Website/App/Marketplace or the Services will
          automatically terminate.
          <br />
          <br />
          CONTENT
          <br />
          You acknowledge that all information (such as data files, written
          text, computer software, music, audio files, or other sounds,
          photographs, videos, or other images) that you access through the
          Services are the sole responsibility of the person from whom such
          content originated. You agree that you are solely and exclusively
          responsible for any and all content that you create, transmit, or
          display while using the Services, and you are solely and exclusively
          liable for the consequences of your actions, including any loss or
          damage that Fit Silhouette may suffer. Fit Silhouette does not
          exercise control over the content posted on the Website/App using the
          Services. Your use of the content accessed or displayed using the
          Services is entirely at your own risk. The use of the Services may
          produce results and links to sites and content that you may find
          objectionable, inappropriate, or offensive, and we accept no liability
          or responsibility for any such content. It is also possible that your
          use of the Services will return information regarding products,
          merchants, and links to websites of third parties selling the product
          information you requested. Fit Silhouette hereby disclaims any and all
          responsibility and liability associated with such content posted by
          said vendors, including, without limitation, in terms of the
          authenticity or reliability of such content, in any manner whatsoever.
          <br />
          You acknowledge and agree that Fit Silhouette is not liable for any
          loss or damage that may be incurred by you as a result of the
          availability of such external sites or resources, or as a result of
          any reliance placed by you on the completeness, accuracy, or existence
          of any advertising, products, or other materials on, or available
          from, such websites or resources. You shall be responsible for
          monitoring your content, documents, and other data and shall be liable
          to us for ensuring that the content transferred to or handled by or
          within Services does not infringe any third-party rights. You warrant
          that you possess the necessary licenses required to process your
          content or use the Services. You understand that the technical
          processing and transmission of the Services, including your content,
          may be transferred unencrypted and involve: (a) transmissions over
          various networks; and (b) changes to conform and adapt to the
          technical requirements of connecting networks or devices. You hereby
          acknowledge and understand that as part of selling products on the
          Marketplace, various vendors may post images, descriptions, and other
          content. We, as Fit Silhouette, are independent aggregators of these
          vendors and will have no liability in relation to such content posted
          by the said vendors, including, without limitation, in terms of the
          authenticity or reliability of such content, in any manner whatsoever.
          DISCLAIMERS Your correspondence or business dealings with, or
          participation in promotions of, advertisers found on or through the
          Service or of vendors on the Marketplace, including payment and
          delivery of related goods or services, and any other terms,
          conditions, warranties, or representations associated with such
          dealings, are solely between you and such advertisers or vendors. You
          agree that, save as set out herein, Fit Silhouette shall not be
          responsible or liable for any loss or damage of any sort incurred as a
          result of any such dealings or as a result of the presence of such
          advertisers or vendors on the Services/the Marketplace. The Services
          may provide, or third parties may provide, links to other websites or
          resources. Given that we have no control over such sites and
          resources, you acknowledge and agree that we are not responsible for
          the availability of such external sites or resources and do not
          endorse and are not responsible or liable for any content,
          advertising, products, or other materials on or available from such
          sites or resources. You further acknowledge and agree that we shall
          not be responsible or liable, directly or indirectly, for any damage
          or loss caused or alleged to be caused by or in connection with the
          use of or reliance on any such content, goods, or services available
          on or through any such site or resource. You agree that your use of
          the Website/App/Marketplace and the Services shall be at your sole
          risk. You expressly understand and agree that, to the maximum extent
          permitted by applicable law, the Website/App/Marketplace and the
          Services are provided on an "as is" and "as available" basis for your
          use, without warranties of any kind, express or implied, including
          without limitation the warranties of merchantability, fitness for a
          particular purpose, title, non-infringement, and those arising from
          the course of dealing or usage of trade. Fit Silhouette shall use all
          reasonable endeavours to ensure that the Services are uninterrupted,
          but it does not guarantee or warrant that: (i) the Services will meet
          your specific requirements; (ii) the Services will be uninterrupted,
          timely, secure, or error-free; (iii) the results that may be obtained
          from the use of the Services will be accurate or reliable; and (iv)
          the quality of any products, services, information, or other material
          purchased or obtained by you through the Services will meet your
          expectations; and (v) any errors in the Services will be corrected.If
          you are older than 35 (thirty-five) years of age, or if you have not
          been physically active for more than 1 (one) year, or if you have any
          medical history that may put you at risk, including, without
          limitation, one or more of the following conditions, you are required
          to seek approval from a qualified health care practitioner before
          using the Services: Heart disease
          <br />• High blood pressure
          <br />• Family history of high blood pressure or heart disease
          <br />• Chest pain caused by previous exercise
          <br />• Dizziness or loss of consciousness caused by previous exercise
          <br />• Bone or joint problems
          <br />• Diabetes, high cholesterol, obesity
          <br />• Arthritis
          <br />
          You should discontinue exercise in cases where it causes pain or
          severe discomfort and should consult a medical expert before returning
          to exercise in such cases. We reserve the right to deny you access to
          the Services for any reason or no reason, including if we determine,
          in our sole discretion, that you have certain medical conditions. The
          content of the Website/App/Marketplace, including, without limitation,
          text, copy, audio, video, photographs, illustrations, graphics, and
          other visuals, is for informational purposes only and does not
          constitute or substitute professional medical advice, diagnosis,
          treatment, or recommendations of any kind. You should always seek the
          advice of your qualified healthcare professionals with any questions
          or concerns you may have regarding your individual needs and any
          medical conditions. You agree that you will not under any
          circumstances disregard any professional medical advice or delay in
          seeking such advice in reliance on any content provided on or through
          the Website/App/Marketplace. Reliance on any such content is solely at
          your own risk. The content provided on or through this Website/App
          regarding drug or dietary supplements or products for sale on the
          Marketplace has not been evaluated or approved by any regulatory
          authority, including the Drug Controller of India.
          <br />
          <br />
          CONTENT LICENSE FROM YOU
          <br />
          <br />
          Some areas of the Services may allow users to post news, feedback,
          comments, questions, data, documents, pictures, and other information
          ("User Content"). You are solely responsible for your User Content
          that you upload, publish, display, link to, or otherwise make
          available on the Website/App/Marketplace, and you agree that we are
          only acting as a passive conduit for your online distribution and
          publication of your User Content. Fit Silhouette will not review,
          distribute, or reference any such User Content except as provided
          herein or in our Privacy Policy or as may be required by law. By
          posting any User Content on the Website/App/Marketplace, you expressly
          grant, and you represent and warrant that you have the right to grant,
          to Fit Silhouette a royalty-free.
          <br />
          <br />
          BILLING AND PAYMENT
          <br />
          <br />
          Certain aspects of the Services may be provided for a fee or other
          charge. If you choose to use paid aspects of the Services, you agree
          to the terms of sale, pricing, payment, and billing policies
          applicable to such fees and charges. Fit Silhouette may introduce new
          services for additional fees and charges or modify fees and charges
          for existing services at its sole discretion, without prior notice.It
          is your responsibility to promptly provide Fit Silhouette with any
          changes or updates to your contact or billing information, including
          phone number, email address, credit card numbers, etc. Fit Silhouette
          does not validate all credit card information required by the
          customer's payment provider to secure payment. If you decide to
          upgrade any of the Services provided through in-app purchases, the
          payment will be charged to your credit/debit card or net banking
          through your iTunes account or Google Play Billing account at the
          confirmation of purchase. The subscription will automatically renew
          unless cancelled at least 24 (twenty-four) hours before the end of the
          subscription period. In-app purchases exceeding Rs. 5,000 (Rupees Two
          thousand) per transaction will require additional factor
          authentication, as mandated by the Reserve Bank of India. You must
          notify Fit Silhouette about any billing problems or discrepancies
          within 30 (thirty) days after charges first appear on your account
          statement. If you do not notify Fit Silhouette within 30 (thirty)
          days, you agree to waive your right to dispute such problems or
          discrepancies. Regarding purchases made on the Marketplace, you
          acknowledge and agree that no refund requests will be entertained in
          any manner whatsoever, and no refunds will be initiated for such
          purchases.
          <br />
          <br />
          INDEMNITY AND LIMITATION OF LIABILITY
          <br />
          <br />
          You agree to defend, indemnify, and hold harmless Fit Silhouette, its
          officers, directors, employees, and agents from and against any and
          all claims, damages, obligations, losses, liabilities, costs or debt,
          and expenses (including but not limited to attorney's fees) arising
          from: (i) your use of and access to the Service and Website/App/
          Marketplace; (ii) your violation of any term of this Agreement; (iii)
          your violation of any third-party right, including, without
          limitation, any copyright, property, or privacy right; or (iv) any
          claim that your User Content caused damage to a third party. This
          defence and indemnification obligation will survive this Agreement and
          your use of the Service and Website/App/Marketplace. The material
          displayed on our Website or App/Marketplace is provided without any
          guarantees, conditions, or warranties as to its accuracy. To the
          extent permitted by law, we, our subsidiaries, affiliates, and third
          parties connected to us expressly exclude: • Conditions, warranties,
          and other terms that might otherwise be implied by statute, common
          law, or the law of equity. • Any liability for any direct, indirect,
          or consequential loss or damage incurred by any user in connection
          with the Services, our Website/App/Marketplace, or in connection with
          the use, inability to use, or results of the use of the Services or
          our Website/App/Marketplace, any websites linked to it, and any
          materials posted on it, including, without limitation, any liability
          for:
          <br />• Loss of income or revenue
          <br />• Loss of business
          <br />• Loss of profits or contracts
          <br />• Loss of anticipated savings
          <br />• Loss of data
          <br />• Loss of goodwill
          <br />• Wasted management or office time
          <br />• For any other loss or damage of any kind, however arising and
          whether caused by tort (including negligence), breach of contract, or
          otherwise, even if foreseeable or advised of the possibility of the
          same.
          <br />
          Our liability to you shall under all circumstances be limited to a
          maximum of the amount paid by you (if any) at the time of registration
          on our Website/App to use the Services and/or the purchase price paid
          by you for Products in the Marketplace.
          <br />
          <br />
          OFFENCES
          <br />
          <br />
          You must not misuse our Website/App/Marketplace by knowingly
          introducing viruses, trojans, worms, logic bombs, time bombs, cancel
          bots, spyware, or other material that is malicious or technologically
          harmful, or that may limit the interests or rights of other users or
          limit the functionality of any computer software, hardware, or
          telecommunications. You must not attempt to gain unauthorised access
          to our Website/App/Marketplace, the server on which our
          Website/App/Marketplace is stored, or any server, computer, or
          database connected to our site. You must not attack our
          Website/App/Marketplace via a denial-of-service attack or a
          distributed denialof-service attack. By breaching this provision, you
          would commit a criminal offence under the Information Technology Act,
          2000 (and any amendments). We will report any such breach to the
          relevant law enforcement authorities and cooperate with those
          authorities by disclosing your identity to them. In the event of such
          a breach, your right to use our Website/App/ Marketplace will cease
          immediately. We will not be liable for any loss or damage caused by a
          distributed denial-of-service attack, viruses, or other
          technologically harmful material that may infect your computer
          equipment, computer programs, data, or other proprietary material due
          to your use of our Website/App/Marketplace or your downloading of any
          material posted on it, or on any Website/App/Marketplace linked to it.
          <br />
          <br />
          LINKING TO THE WEBSITE
          <br />
          <br />
          You are allowed to link to our home page in a fair and legal manner
          that does not harm our reputation or create a false association with
          us. However, you must not establish a link in a way that suggests any
          form of approval or endorsement from us where none exists. It is
          essential that you do not link to our website from any site that you
          do not own. Our website must not be framed on any other site, and you
          may only link to our home page, not any other part of our site. We
          reserve the right to withdraw linking permission without notice. The
          website from which you are linking must comply with all the content
          standards mentioned in these terms and conditions. Please note that
          any linked sites are not under the control of Fit Silhouette, and we
          are not responsible for the content of those linked sites or any
          changes or updates to them. We are not liable for any transmission
          received by you from any linked site. We provide links for your
          convenience, and the inclusion of any link does not imply endorsement
          or association with the operators or owners of the linked sites. We
          recommend verifying the accuracy of any information on linked sites
          before relying on it. If you wish to use material from our website
          other than as described above, please contact us at
          info@fitsilhouette.com. ADDITIONAL TERMS FOR THE MOBILE APP By using
          our mobile app (Mobile App) and desktop app (Desktop App), you are
          granted a limited, non-sub-licensable right to access the Mobile App,
          Desktop App, Services, and Data to access the Website/App/Marketplace
          and/or Services through thirdparty software or websites. However, you
          are solely responsible for any services and/or products you provide
          through any third-party software, and we have no responsibility or
          liability for such services and products.You understand and agree that
          we are not liable for any direct, indirect, incidental, special,
          consequential, or exemplary damages resulting from your use of the
          Mobile App and Desktop App. You must not use the Mobile App or Desktop
          App to send unsolicited communications to any third party, whether
          commercial or otherwise. We reserve the right to modify or discontinue
          the Desktop App and Mobile App with or without notice. Abuse or
          excessively frequent requests to the Services via the Desktop App and
          Mobile App may result in the temporary or permanent suspension of your
          account's access. We may provide software to access the Services via
          the App using a mobile device. However, we do not guarantee that the
          App will be compatible with your mobile device. You are granted a
          non-exclusive, non-transferable, revocable license to use the App for
          one Member account on one mobile device owned solely by you, for your
          personal use. You may not modify, disassemble, decompile, or reverse
          engineer the App or rent, lease, loan, resell, sublicense, distribute,
          or transfer it to any third party. SECURITY We have taken commercially
          reasonable measures to secure your personal information and User
          Content from unauthorised access or accidental loss. However, we
          cannot guarantee that unauthorised third parties will not defeat these
          measures and use your information for improper purposes. You provide
          your personal information at your own risk. OWNERSHIP OF INTELLECTUAL
          PROPERTY INCLUDING TRADEMARKS/SERVICE MARKS All rights, title, and
          interest in the terms "Fit Silhouette," "FitSilhouette.com," and
          associated content, including but not limited to text, graphics, user
          interfaces, computer code, and other information, are reserved by us.
          All rights, title, and interest in and to the Services (excluding your
          Content) remain the exclusive property of Fit Silhouette and its
          licensors. Any use of this Website/App or its contents, including
          copying or storing them for any purpose other than personal,
          noncommercial use, is prohibited without our permission. You are not
          allowed to copy, reproduce, republish, upload, post, publicly display,
          distribute, or transmit any part of Fit Silhouette's content or marks,
          data, statistics, independent research, or any other information
          provided without prior written consent. By accepting these terms, you
          acknowledge that Fit Silhouette does not transfer or assign the title
          to the Website/App to you, and you understand that all rights,
          including intellectual property rights, remain with Fit Silhouette.
          Redistribution, sale, decompilation, reverse engineering, or any
          action contrary to this agreement is strictly prohibited. Any
          unauthorised usage of Fit Silhouette's content will be considered a
          breach of this agreement, and you will be required to indemnify Fit
          Silhouette for any liability incurred as a result.
          <br />
          <br />
          GOVERNING LAW
          <br />
          <br />
          Any claim arising from or related to your visit to/use of the
          Website/App/Marketplace or the Services shall be subject to the sole
          jurisdiction of the courts in Jammu and Kashmir, India. However, we
          reserve the right to bring proceedings against you for breach of these
          terms and conditions in your country of residence, country of use, or
          any other relevant country. The laws of India govern this Agreement
          and the terms and conditions of using the Services. Please note that
          Fit Silhouette accepts no liability for noncompliance with the laws of
          any country other than India. The fact that the
          Website/App/Marketplace can be accessed or used in a country other
          than India does not imply that we submit to the laws of that country.
          <br />
          <br />
          FAQ on how customers can protect themselves when shopping online.
          <br />
          <br />
          Question: Is it necessary to have an account to purchase products from
          Fit Silhouette?
          <br />
          To avail the services of Fit Silhouette, you need to go through a
          simple registration process. The details provided during registration
          are protected under our Privacy Policy, and we recommend reviewing the
          terms of our privacy policy during registration.
          <br />
          <br />
          Question: How do I make a payment?
          <br />
          Fit Silhouette offers multiple payment methods through trusted payment
          gateway partners. The payment gateway partners use secure encryption
          technology to keep your transaction details confidential. You can make
          your purchase using cash. Fit Silhouette also accepts payments made
          using Visa, MasterCard, Maestro, and American Express credit/debit
          cards in India.
          <br />
          <br />
          Question: Are there any hidden charges (Octroi or Sales Tax) when
          making a payment? There are no hidden charges when making payments on
          Fit Silhouette. The prices listed for all services are final and
          all-inclusive. The price displayed on the webpage is exactly what you
          pay
          <br />
          <br />
          Question: How do I pay using a credit/debit card? Fit Silhouette
          accepts payments made by credit/debit cards issued in India.
          <br />
          <br />
          Credit cards: Payments can be made using Visa, MasterCard, and
          American Express credit cards. To pay using your credit card, you will
          need to enter your card number, expiry date, and three-digit CVV
          number (found on the backside of your card). After providing these
          details, you will be redirected to the bank's page for entering the
          online 3D Secure password.
          <br />
          <br />
          Debit cards: Payments can be made using Visa, MasterCard, and American
          Express debit cards. To pay using your debit card at checkout, you
          will need your card number, expiry date (optional for Maestro cards),
          and three-digit CVV number (optional for Maestro cards). You will then
          be redirected to your bank's secure page for entering your online
          password (issued by your bank) to complete the payment.
          <br />
          <br />
          Question: Is it safe to use my credit/debit card on Fit Silhouette?
          Your online transactions are secure with the highest levels of
          transaction security currently available on the Internet. Fit
          Silhouette uses encryption technology to protect your card information
          while securely transmitting it to the respective banks for payment
          processing.All credit card and debit card payments on Fit Silhouette
          are processed through secure and trusted payment gateways managed by
          leading banks. Banks now use the 3D Secure password service for online
          transactions, providing an additional layer of security through
          identity verification.
          <br />
          <br />
          Question: What steps does Fit Silhouette take to prevent card fraud?
          Fit Silhouette takes fraud detection and resolution seriously. We and
          our online payment partners continuously monitor transactions for
          suspicious activity and flag potentially fraudulent transactions for
          manual verification by our team. In rare instances where our team is
          unable to rule out the possibility of fraud, the transaction is kept
          on hold, and the customer is asked to provide identity documents.
          These documents help us ensure that the payment is being made by a
          genuine cardholder. We apologise for any inconvenience this may cause
          and request the customer's cooperation for a safe and secure
          environment for online transactions. We advise our customers to
          diligently protect against any fraudulent use of their card. Fit
          Silhouette is not responsible for any unauthorised usage of your
          cards.
          <br />
          <br />
          Question: What is a 3D Secure password? A 3D Secure password adds an
          additional layer of security through identity verification for your
          online credit/debit card transactions. This password, created by you,
          is known only to you and ensures that only you can use your card for
          online purchases.
          <br />
          <br />
          Question: Can I use my bank's Internet Banking feature to make a
          payment? Yes. Fit Silhouette offers you the convenience of using your
          bank’s Internet Banking service to make a payment towards your order.
          With this, you can directly transfer funds from your bank account
          while conducting a highly secure transaction. We accept payment
          through Net Banking for the following banks: (Here list the banks
          supported for Net Banking payments)
          <br />
          <br />
          Question: Can I make a credit/debit card or Internet Banking payment
          on Fit Silhouette through my mobile? Yes, you can make credit card
          payments through the Fit Silhouette mobile site and App. Fit
          Silhouette uses encryption technology to protect your card information
          while securely transmitting it to the secure and trusted payment
          gateways managed by leading banks.
          <br />
          <br />
          Question: My transaction failed, but the money was deducted from my
          account. What do I do? If your transaction failed, and the money was
          deducted from your account, please contact us at CUSTOMER SUPPORT
          within 24 hours of the deduction. We will immediately contact our
          payment partner and refer the matter to them for resolution.
          <br />
          <br />
          END USER LICENSE AGREEMENT
          <br />
          <br />
          This End-User License Agreement ("EULA") is a legal and binding
          agreement between you and Fit Silhouette ("Company"). By clicking on
          the “I AGREE” button below and by installing or otherwise using the
          Fit Silhouette application (the "App") on your cellular mobile phone,
          you agree to be bound by the terms and conditions of this agreement.
          If you do not agree to be bound by the agreement or the terms and
          conditions on the cellular mobile phone/start-up application, then do
          not click “I AGREE” or install, copy, and/or use the application. You
          must scroll down to the end of this EULA before you agree to it and
          continue with the installation.
          <br />
          <br />
          LICENSE
          <br />
          <br />
          Subject to the terms and conditions of this EULA, the Company hereby
          grants you a limited, non-commercial, non-sub-licensable,
          non-exclusive, non-transferable right to install and use the App on
          one portable wireless device. You may not make the App available over
          a network where it could be used on multiple devices at the same time.
          The term “App” includes any patches, revisions, updates, upgrades,
          software, code, files, images, and other embedded software or
          replacements of the App (the "Revisions") that may be delivered to you
          or that you may receive or that may be released by the Company, unless
          other terms and conditions are provided with the Revisions. If such
          terms and conditions are provided, you will have to agree to those
          terms and conditions before you are entitled to receive the Revisions.
          You also agree that upon the installation of any Revisions, you are
          only permitted to use the App as modified by the Revisions. You shall
          not either directly or indirectly, or through any third party, modify,
          adapt, translate, redistribute, rent, lend, network, lease, loan,
          issue, resell, for profit, or create derivative works based on the App
          and any portion thereof. You acknowledge that by installing the App on
          your cellular mobile phone, the App may aggregate, collect, retain, or
          transmit to secure servers personal information such as, but not
          limited to, contact information, the serial number, and IMEI number of
          your wireless device or cellular mobile phone. You hereby consent to
          the App collecting and storing such information on the Company's
          servers and to receive calls from us, from time to time, regarding
          updates of your progress from using the App and promotional
          information or offers in connection with the services provided under
          the App. RESTRICTIONS ON USE. The following restrictions shall apply
          to your use of the App and the license granted herein:
          <br />• You hereby acknowledge and agree to use the App as permitted
          in this Agreement.
          <br />• You shall not copy or reproduce any portion of the App.
          <br />• You shall only use the App for your own personal,
          non-commercial use.
          <br />• You shall not distribute, share, transfer, sell, lease, or
          rent the App or any part of it to any other person.
          <br />• You shall not change, alter, modify, or create derivative
          works, enhancements, extensions, or add-ons to the App or any part of
          it.
          <br />• You shall not remove, alter, or obscure any proprietary notice
          (including any notice of copyright or trademark) of the Company or its
          affiliates, partners, suppliers, or the licensors of the App.
          <br />• You shall not use the App for any revenue-generating
          endeavour, commercial enterprise, or other purpose for which it is not
          designed or intended.
          <br />• You shall not use any electronic communication feature of the
          App for any purpose that is unlawful, tortious, abusive, intrusive on
          another’s privacy, harassing, libellous, defamatory, embarrassing,
          obscene, threatening, or hateful.
          <br />• You shall not use any proprietary information or interfaces of
          the Company or other intellectual property of the Company in the
          design, development, manufacture, licensing, or distribution of any
          applications, accessories, or devices for use with the App.
          <br />• You will not collect or store personal information about other
          users.
          <br />• You shall not decompile, reverse engineer, or disassemble the
          App or any part of it either in whole or any portion of any component
          thereof and in any way create, develop or derive, including without
          limitation, any operational, technical, programming
          technique/sequence, feature, interface, algorithm, video, audio,
          imaging, graphics, applets, texts, animations, routine, or conditions
          from the App.
          <br />• Violate any applicable laws, rules, or regulations in
          connection with your access or use of the App.
          <br />• You will at all times comply with, and will not circumvent or
          attempt to circumvent, any of the restrictions on use set forth
          anywhere in this EULA.
          <br />
          DATA CHARGES
          <br />
          You acknowledge that applicable network/data charges may be incurred
          through your use of the App, and you agree that you are responsible
          for any and all such charges that may be charged to you, and that the
          Company shall have no liability to you whatsoever in respect of such
          charges. You also agree that the charges incurred through your use of
          the App are dependent on your agreement with your service provider and
          that the Company has absolutely no liability to you in respect of such
          charges. You agree that use of the App while outside your home circle
          might incur additional roaming charges and agree to be solely liable
          for such charges. The Company has no liability to you in respect of
          such charges. INTELLECTUAL PROPERTY RIGHTS The App may feature
          trademarks, logos, service marks, product names, and designations. By
          making these trademarks available through the App and in content, the
          Company is not selling you the App to use it in any manner, and you
          are not granted any rights under any of the Company's intellectual
          property rights. This EULA does not grant you any rights to
          trademarks, copyrights, or patents of the Company. Any unauthorised
          copying, displaying, selling, or distributing or other use of any
          content or App is a violation of the law.
          <br />
          <br />
          DISCLAIMER
          <br />
          You expressly acknowledge and agree that you are installing and using
          the App at your own risk. The App and all data and content provided
          through the App are provided "as is", "with all faults", and without
          warranty, terms, or conditions of any kind. The Company and each of
          its licensees, affiliates, and authorised representatives (the
          "Parties" or "Party") expressly and specifically disclaim all
          warranties, terms, or conditions, whether express or implied,
          including, but not limited to, any implied warranties of
          merchantability, satisfactory quality, non-infringement, and fitness
          for a general or particular purpose with respect to the App and
          content or those arising from course of dealing or usage of trade. The
          Company cannot and does not warrant that the App will operate without
          interruptions, that it will be free from viruses and errors, or that
          the App will not interfere with the functioning of other software or
          programs installed on your cellular mobile device and will meet your
          requirements. No oral, written, or electronic information or advice
          given by any party shall create any warranty, term, or condition with
          respect to the App or otherwise. The App may be subject to breaches of
          security, and the Company shall not be held responsible for any
          resulting damage to any user's device from any such breach, including
          but not limited to any virus, bugs, tampering, unauthorised
          intervention, omission, deletion, or defect. The Company provides no
          assurance that any specific errors or discrepancies will be
          corrected.The Company is not liable for any network faults or downtime
          in network services. You agree and acknowledge that relay of text
          messages is network dependent, and the Company is not responsible for
          any messages failing to reach their intended recipients. The App uses
          the read-only memory (ROM) of your cellular mobile phone to function
          efficiently and effectively. You agree and acknowledge that the App
          might automatically hibernate or go into standby mode if the ROM on
          your cellular mobile phone is insufficient to run the OEM apps and
          functions. The Company is not responsible for the App hibernating, and
          you agree that you are solely responsible to keep your cellular mobile
          phone optimised. It is possible that the App may get locked or stop
          working if the data on your cellular mobile phone causes your phone to
          crash or requires the operating software to be reinstalled. The
          Company is not liable or responsible to you in any manner whatsoever
          for such an occurrence.
          <br />
          <br />
          LIMITATION OF LIABILITY
          <br />
          You understand and agree that under no circumstance will the Company,
          its agents, licensors, or suppliers be liable to you on account of
          damages of any kind, whether based in tort, contract, strict
          liability, or otherwise, arising from your misuse of the software. The
          Company shall not be liable for any loss or damages, including but not
          limited to damages of personal injury, either direct, indirect,
          incidental, consequential, or otherwise, arising out of the breach of
          any express or implied warranty, term, or condition, breach of
          contract, negligence, strict liability, misrepresentation, failure of
          any remedy to achieve its essential purpose, or any other legal theory
          arising out of, or related to, this agreement or your use of the App
          (such damages include, but are not limited to, loss of profits,
          business interruption, savings, loss of privacy, or any other
          pecuniary loss, loss of revenue, loss of data, loss of use of the App
          or the cellular mobile device or any associated equipment or
          documents), even if the Company has been advised of the possibility of
          such damages. In any case, the entire liability of the Company shall
          be limited to the amount paid by you to subscribe to the services
          provided on the App.
          <br />
          <br />
          DAMAGES
          <br />
          You shall defend and hold Company harmless from and against any and
          all liabilities, damages, costs, expenses, or losses arising out of
          your improper or unauthorised use of the App, your negligent or
          wrongful acts, your violation of any applicable laws or regulations,
          and/or your breach of any provision of this EULA.
          <br />
          <br />
          EVALUATION TERM & TERMINATION
          <br />
          Any license not purchased for the full license fee and any trial
          version are provided for a limited duration and for the limited
          purpose expressly communicated to you in connection with the App. At
          the end of the evaluation/trial period, you will cease use of the App,
          remove the App from your cellular mobile device, and destroy all
          copies of the App. Failure to remove the App shall be a breach of this
          EULA. You agree that the App may automatically disable itself at the
          end of the evaluation/ trial period and consent to such disabling
          feature. You also agree that the Company may send you reminders to
          purchase a permanent license key, whether during or after the
          evaluation/trial version. You agree that any evaluation/trial version
          is not full-featured and the use of certain features of the App may be
          disabled or limited in any manner deemed appropriate. You may activate
          all features of the App by purchasing a license key as communicated to
          you. Without prejudice to any other rights, the Company may terminate
          this EULA if you fail to comply with or breach the terms and
          conditions of this EULA or for any other reason upon delivery of
          notice, unless such breach, where capable of cure, is cured by you
          immediately upon receipt of a notice of such breach from the Company.
          In such an event, you must cease use of the App and destroy all copies
          of the App and all of its component parts and associated documents.
          You agree that the Company may remotely disable the App in the event
          of termination under this section.
          <br />
          <br />
          ENTIRE AGREEMENT
          <br />
          This EULA constitutes the complete and exclusive agreement between you
          and Company concerning the App, replacing all prior or contemporaneous
          oral or written communications, including any terms and conditions of
          purchase, proposals, or representations related to the App. The
          headings in this agreement are for convenience and reference only and
          do not affect the interpretation of this EULA. The terms of service
          and privacy policy provided above are integral parts of this EULA, and
          together they govern your use of the App and access to the services
          offered on the Fit Silhouette website and App.
          <br />
          <br />
          SEVERABILITY
          <br />
          If any provision of this EULA is deemed void, invalid, unenforceable,
          or illegal by a court of competent jurisdiction, the remaining
          provisions shall remain in full force and effect. In such cases, the
          invalid or unenforceable provision(s) will be replaced with valid and
          enforceable ones that fulfil the original purpose of the affected
          provision(s).
          <br />
          <br />
          GOVERNING LAW
          <br />
          This EULA is governed by the laws of the Republic of India. Any
          disputes arising from this agreement shall be subject to the
          jurisdiction of the courts in Jammu and Kashmir, and you agree to
          submit to such jurisdiction.
          <br />
          <br /> REFUND POLICY
          <br />
          All subscriptions with Fit Silhouette are non-refundable. Once you
          have subscribed to a plan, no refunds will be provided regardless of
          the plan duration or the reason for cancellation.
          <br />
          <br />
          Privacy Policy
          <br />
          This privacy policy ("Policy") UPDATE on 20 August 2023. We may
          periodically make revisions to the Policy, and in such instances, we
          will notify you either through email or by sending a notification via
          our mobile application ("App"). We recommend that you carefully review
          any such notices. At Fit Silhouette, we firmly believe in your right
          to know what data we collect from you and the purposes for which we
          use it. We are committed to ensuring that you can make informed
          decisions about the data you choose to share with us. To be fully
          transparent, we provide details on: (i) the collection, storage,
          sharing, and usage of your personal data through your interactions
          with us; and (ii) your rights concerning this interaction. Although we
          encourage you to read the Policy in its entirety, the following
          summary will provide an overview of the key points covered herein:
          <br />• This Policy outlines the essential aspects governing your
          personal data relationship with 'Fit Silhouette,' which is owned and
          operated by Fit Silhouette Wellness Private Limited, a company
          incorporated under the laws of India, with its registered office at
          Badhoon, Rajouri jammu and kashmir, 185132.
          <br />• Your personal data relationship with Fit Silhouette may differ
          depending on whether you are a visitor to our website
          https://www.fitsilhouette.com ("Website") or any of its pages
          ("Visitor"), or a registered user who avails our services ("Registered
          User").
          <br />• The type of data we collect and the purposes for which we use
          it vary based on whether you are a Visitor or a Registered User. This
          Policy provides detailed information on these distinctions.
          <br />• This Policy is applicable to all the services offered by Fit
          Silhouette through the Website, App, or any other associated website.
          <br />• It is crucial to read this Policy alongside our Terms of
          Service.
          <br />• This Policy outlines your rights concerning the personal data
          you share with us. If you have any questions or concerns regarding
          this Policy, please contact our Grievance Officer. If you do not agree
          with the Policy, we recommend refraining from visiting or using the
          Website or the App.
        </Description>
      </MainDiv>
    </MainDiv>
  );
}

export default Terms;

const MainDiv = styled.div`
  align-self: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const HeadingText = styled.h1`
  font-size: 20px;
  color: black;
  text-align: center;
  font-family: Gilroy-Regular, Helvetica;
  font-weight: 700;
  margin-top: 40px;
`;
const Description = styled.h1`
  font-size: 17px;

  font-family: Gilroy-Regular, Helvetica;
  font-weight: 500;
  color: black;

  margin-top: 5px;
`;
