import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import { useRef } from "react";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import HomeScreen from "./Components/HomeScreen";
import Aboutus from "./Components/LegalTerms/Aboutus";
import Privacy from "./Components/LegalTerms/Privacy";
import Refund from "./Components/LegalTerms/Refund";
import Shipping from "./Components/LegalTerms/Shipping";
import Terms from "./Components/LegalTerms/Terms";
import ScrollToTopOnNavigate from "./Components/ScrollToTopOnNavigate ";
import PaymentPlans from "./Components/payments";

function App() {
  // const Whatwearecontainer = useRef(null);

  const HomeContainer = useRef(null);
  const Whatwearecontainer = useRef(null);
  const OurFeaturesContainer = useRef(null);

  return (
    <Router>
      <ScrollToTopOnNavigate />{" "}
      {/* Add this component to ensure scrolling to the top on navigation */}
      <Header
        HomeContainer={HomeContainer}
        Whatwearecontainer={Whatwearecontainer}
        OurFeaturesContainer={OurFeaturesContainer}
      />
      <Routes>
        <Route
          path="/"
          element={
            <HomeScreen
              HomeContainer={HomeContainer}
              Whatwearecontainer={Whatwearecontainer}
              OurFeaturesContainer={OurFeaturesContainer}
            />
          }
        />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/about" element={<Aboutus />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/shippingpolicy" element={<Shipping />} />
        <Route path="/cancellationandrefund" element={<Refund />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/paymentspage" element={<PaymentPlans />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
