import React from "react";
import { styled } from "styled-components";
// import logo from "../../images/logo.webp";

function Privacy() {
  return (
    <MainDiv style={{ width: "100%", alignItems: "center" }}>
      {/* <img
        src={
          // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
          logo
        }
        alt="Description of the image"
        height={"90px"}
        width={"90px"}
        // width={"00px"}
        // style={{ marginLeft: 30, marginRight: 10 }}
      /> */}
      <MainDiv
        style={{
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        <HeadingText>PRIVACY POLICY</HeadingText>
        <Description>
          This privacy policy (“Policy”) implied on September 9,2023. We may
          occasionally make changes to the Policy. If we do, we shall notify you
          either by sending you an e-mail or sending you a notification through
          our mobile application (“App”). Please make sure to read such notices
          carefully.
          <br />
          We hold the sincere belief that you should always know what data we
          collect from you, the purposes for which such data is used, and that
          you should have the ability to make informed decisions about what data
          you want to share with us.
          <br />
          This is why we want to be fully transparent about: (i) how and why we
          collect, store, share and use your personal data through the various
          capacities in which you interact with us; and (ii) the rights that you
          have to determine the parameters of this interaction. While we would
          strongly advise you to read the Policy in full, the following summary
          will give you a snapshot of the salient points covered herein:
          <br />
          This Policy details the critical aspects governing your personal data
          relationship with ‘Fit Silhouette,’ which is owned and operated by Fit
          Silhouette Wellness Private Limited, a company incorporated under the
          laws of India, and having its registered office at 46, Badhoon,
          Rajouri, Jammu and Kashmir - 185235, India;
          <br />
          Your personal data relationship with Fit Silhouette varies based on
          the capacity in which you interact with us/avail of our products and
          services (“Services”). You could be: (i) a visitor to https://
          www.fitsilhouette.com (“Website”) or any pages thereof (“Visitor”); or
          (ii) a person who has an account with Fit Silhouette to avail our
          Services (“Registered User”);
          <br />
          Based on whether you are a Visitor or Registered User, the type of
          data we collect and the purpose for which we use it will differ, and
          this Policy details such variations;
          <br />
          This Policy applies to all the Services provided by Fit Silhouette
          through the Website, App, or any other associated website of Fit
          Silhouette; This Policy is a part of and should be read in conjunction
          with our Terms of Service; and
          <br />
          This Policy will clarify the rights available to you vis-à-vis the
          personal data you share with us.
          <br />
          If you have any queries or concerns with this Policy, please contact
          our Grievance Officer. If you do not agree with the Policy, we would
          advise you to not visit/use the Website or the App.
        </Description>

        <HeadingText>1. INFORMATION WE COLLECT AND HOW WE USE IT</HeadingText>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>TYPE OF USER</TableHeader>
              <TableHeader>VISITOR</TableHeader>
              <TableHeader>REGISTERED USER</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            <TableRow>
              <TableData style={{ fontWeight: 700 }}>
                WHAT DATA WE MAY COLLECT
              </TableData>
              <TableData>
                Your IP Address; Your location; How you behave on the Website
                (what pages you land on, how much time you spend, etc.); What
                device you use to access the Website and its details (model,
                operating system, etc.); and Cookies and web beacon data.
              </TableData>
              <TableData>
                Your name, age, gender, phone number, e-mail address, mailing
                address; Your height, weight; Your lifestyle, food preferences,
                medical conditions (if any), health goals, or other fitness
                regimes; Your ethnicity, genetics, health, or sexual
                orientation; Username or other relevant unique identifier; How
                you behave on the Website (what pages you land on, how much time
                you spend, etc.); Credit card/debit card/other payment mode
                information to facilitate payments for our Services; Your IP
                Address; Your location unless you deactivate location services
                in the relevant section of the Website/App; How you behave in
                the relevant product environment and use the features; What
                device you use to access the Website/ App and its details
                (model, operating system, etc.); and Cookies and web beacon
                data.
              </TableData>
            </TableRow>
            <TableRow>
              <TableData style={{ fontWeight: 700 }}>
                HOW AND WHY WE USE IT
              </TableData>
              <TableData>
                We use this information to analyze and identify your behavior
                and enhance the interactions you have with the Website. If you
                submit your details and give us your consent, we may use your
                data to send you e-mails/newsletters, re-target Fit Silhouette
                advertisements, or remarket our Services using services from
                third-parties like Facebook and Google. We collect this data in
                order to help you register for and facilitate the provision of
                our Services. We use this data to show age-appropriate content
                and advertisements. We use this data to track your nutrition,
                weight, and fitness regimes. We use the data from the device you
                access our Website/App to identify the login information of
                multiple users from the same device. We use this data to prepare
                a specific diet plan and training regime for you. We use this
                data to enable you to make payments for our Services. We use a
                third-party service provider to manage payment processing. This
                service provider is not permitted to store, retain, or use
                information you provide except for the sole purpose of payment
                processing on our behalf We use this data for processing your
                requests, inquiries, and complaints, customer services, and
                related activities. We use this data to communicate about
                existing or new offers, content, advertisements, surveys, key
                policies, or other administrative information. We also use this
                data to provide you with informative features and services that
                are developed by us from time to time. We also use this data for
                providing, testing, improving, or recommending the Services. We
                also use this data for analytics and reviews to improve the
                Services. We also use this data to provide support to law
                enforcement agencies or in connection with an investigation on
                matters related to public safety, as permitted by law or
                anti-fraud activities/systems. If you give us your consent, we
                may send you newsletters and emails to market other products and
                services we may provide.
              </TableData>
              <TableData></TableData>
            </TableRow>
            <TableRow>
              <TableData style={{ fontWeight: 700 }}>
                HOW AND WHY WE USE IT
              </TableData>
              <TableData>
                We use this information to analyze and identify your behavior
                and enhance the interactions you have with the Website. If you
                submit your details and give us your consent, we may use your
                data to send you e-mails/newsletters, re-target Fit Silhouette
                advertisements, or remarket our Services using services from
                third-parties like Facebook and Google. We collect this data in
                order to help you register for and facilitate the provision of
                our Services. We use this data to show age-appropriate content
                and advertisements. We use this data to track your nutrition,
                weight, and fitness regimes. We use the data from the device you
                access our Website/App to identify the login information of
                multiple users from the same device. We use this data to prepare
                a specific diet plan and training regime for you. We use this
                data to enable you to make payments for our Services. We use a
                third-party service provider to manage payment processing. This
                service provider is not permitted to store, retain, or use
                information you provide except for the sole purpose of payment
                processing on our behalf We use this data for processing your
                requests, inquiries, and complaints, customer services, and
                related activities. We use this data to communicate about
                existing or new offers, content, advertisements, surveys, key
                policies, or other administrative information. We also use this
                data to provide you with informative features and services that
                are developed by us from time to time. We also use this data for
                providing, testing, improving, or recommending the Services. We
                also use this data for analytics and reviews to improve the
                Services. We also use this data to provide support to law
                enforcement agencies or in connection with an investigation on
                matters related to public safety, as permitted by law or
                anti-fraud activities/systems. If you give us your consent, we
                may send you newsletters and emails to market other products and
                services we may provide.
              </TableData>
              <TableData></TableData>
            </TableRow>
            {/* Add more rows as needed */}
          </tbody>
        </Table>
        <HeadingText style={{ fontSize: "16px" }}>
          WHAT ARE THE COOKIES AND WEB BEACONS REFERRED TO ABOVE?
        </HeadingText>
        <Table>
          <thead>
            <TableRow>
              <TableHeader>TYPE OF FILE</TableHeader>
              <TableHeader>COOKIES</TableHeader>
              <TableHeader>WEB BEACON</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            <TableRow>
              <TableData style={{ fontWeight: 700 }}>WHAT THEY DO</TableData>
              <TableData>
                Cookies are text files that are sent from our server and
                downloaded to your device when you visit our Website. They are
                useful because they allow us to recognize your device when you
                return. You can disable them through your browser should you so
                wish. We use cookies to see which parts of our Website are used
                by Registered Users/Visitors and to record the number of visits
                to our Website. You can find more information about cookies at:
                www.allaboutcookies.org Web beacon (also known as Clear GIF, Web
                Bugs, or Pixel Tag) is a tiny picture file embedded on the
                Website/App that tracks your behavior and navigation. It is
                similar to a cookie in what it does, but it does not get
                downloaded onto your device. We use web beacons to manage
                cookies, record visits, and to learn marketing metrics. We also
                use web beacons to track and monitor email opening rates as well
                as link clicks. You can find more information about web beacons
                at: http:// www.allaboutcookies.org/faqs/ beacons.html
              </TableData>
              <TableData></TableData>
            </TableRow>
            <TableRow>
              <TableData style={{ fontWeight: 700 }}>
                WHAT THEY WON’T DO
              </TableData>
              <TableData>
                Contain any executable software, malicious code, or virus.
                Usually, browsers have a default setting to accept cookies until
                you change your browser settings. You can choose to reject and
                remove cookies from our Website by changing your browser
                settings. If you reject or remove our cookies, it could affect
                how our Website works. FOR THE AVOIDANCE OF ANY DOUBT, WE SHOULD
                CLARIFY THAT IN THE EVENT WE ANONYMIZE AND AGGREGATE INFORMATION
                COLLECTED FROM YOU, WE WILL BE ENTITLED TO USE SUCH ANONYMIZED
                DATA FREELY, WITHOUT ANY RESTRICTIONS OTHER THAN THOSE SET OUT
                UNDER APPLICABLE LAW.
              </TableData>
              <TableData></TableData>
            </TableRow>
          </tbody>
        </Table>
        <HeadingText>
          2. YOUR RIGHTS & PREFERENCES AS A DATA SUBJECT IN THE EU
        </HeadingText>
        <Description>
          It might have come to your attention that a new law was passed in the
          European Union (EU) called the General Data Protection Regulation
          (GDPR). The GDPR gives certain rights to individuals who are EU data
          subjects, in relation to their personal data. Subject to the GDPR and
          applicable law’s limitations, the rights afforded to you if you are an
          EU data subject are:
        </Description>

        <HeadingText>RIGHT TO BE INFORMED</HeadingText>
        <Description>
          You have a right to be informed about the manner in which any of your
          personal data is collected or used, which we have endeavored to do by
          way of this Policy.
        </Description>

        <HeadingText>RIGHT OF ACCESS</HeadingText>
        <Description>
          You have a right to access the personal data you have provided by
          requesting us to provide you with the same.
        </Description>

        <HeadingText>RIGHT TO RECTIFICATION</HeadingText>
        <Description>
          You have a right to request us to amend or update your personal data
          if it is inaccurate or incomplete.
        </Description>

        <HeadingText>RIGHT TO ERASURE</HeadingText>
        <Description>
          You have a right to request us to delete your personal data.
        </Description>

        <HeadingText>RIGHT TO RESTRICT</HeadingText>
        <Description>
          You have a right to request us to temporarily or permanently stop
          processing all or some of your personal data.
        </Description>

        <HeadingText>RIGHT TO OBJECT</HeadingText>
        <Description>
          You have a right, at any time, to object to our processing of your
          personal data under certain circumstances. You have an absolute right
          to object to us processing your personal data for the purposes of
          direct marketing.
        </Description>

        <HeadingText>RIGHT TO DATA PORTABILITY</HeadingText>
        <Description>
          You have a right to request us to provide you with a copy of your
          personal data in electronic format, and you can transmit that personal
          data for using another third-party’s product/service.
        </Description>

        <HeadingText>
          RIGHT NOT TO BE SUBJECT TO AUTOMATED DECISION-MAKING
        </HeadingText>
        <Description>
          You have a right not to be subject to a decision based solely on
          automated decisionmaking, including profiling.
          <br />
          In case you want to exercise the rights set out above, you can contact
          our Grievance Officer whose details are set out in Section 13 below.
          <br />
          The data provided by you as a Visitor, or when you sign up as a
          Registered User for our Services will be processed by us for the
          purpose of rendering Services to you or in order to take steps prior
          to rendering such Services, at your request. Where such data is not
          being used by us to render Services to you, we shall explicitly seek
          your consent for using the same. You can choose to withdraw this
          consent at any time by writing to us at complaints@fitsilhouette.com.
          Additionally, we may process your data to serve legitimate interests.
          <br />
          Accordingly, the grounds on which we can engage in processing are as
          follows:
        </Description>

        {/* Another Table */}
        <Table>
          <thead>
            <TableRow>
              <TableHeader>NATURE OF DATA</TableHeader>
              <TableHeader>GROUNDS</TableHeader>
            </TableRow>
          </thead>
          <tbody>
            <TableRow>
              <TableData>Visitor Data</TableData>
              <TableData>
                Consent; Performance of a Contract; and Legitimate Interest.
              </TableData>
            </TableRow>

            <TableRow>
              <TableData>Account Registration Data</TableData>
              <TableData>
                Performance of a Contract; and Legitimate Interest.
              </TableData>
            </TableRow>

            <TableRow>
              <TableData>Special Categories of Personal Data</TableData>
              <TableData>Consent; and Performance of a Contract.</TableData>
            </TableRow>

            <TableRow>
              <TableData>Payment Data</TableData>
              <TableData>
                Legitimate Interest; and Performance of a Contract; and
                Compliance with applicable laws.
              </TableData>
            </TableRow>

            <TableRow>
              <TableData>Service Usage Data</TableData>
              <TableData>
                Performance of a Contract; and Legitimate Interest.
              </TableData>
            </TableRow>

            <TableRow>
              <TableData>Data for Marketing our Services</TableData>
              <TableData>Consent; and Legitimate Interest.</TableData>
            </TableRow>

            <TableRow>
              <TableData>Personal Stories</TableData>
              <TableData>Consent.</TableData>
            </TableRow>
          </tbody>
        </Table>

        <Description>
          If you believe we have used your personal data in violation of the
          rights above or have not responded to your objections, you may lodge a
          complaint with your local supervisory authority.
        </Description>
        <HeadingText>3. PUBLISHED CONTENT</HeadingText>
        <Description>
          Any success stories, comments, messages, blogs, scribbles, etc.
          posted/uploaded/conveyed/ communicated by you on the public sections
          of the Website/App become published content. We may publish such
          published content on our Website/App so long as you consent. You may
          request that such published content be taken down at any time, and we
          shall remove such published content. However, we are not responsible
          for any actions taken by third-parties with respect to such published
          content.
        </Description>

        <HeadingText>4. INFORMATION WE GET FROM OTHERS</HeadingText>
        <Description>
          We may receive data about you from other sources, i.e., expert calls,
          smartwatches, surveys, and such data may be added to our Website/App
          from time to time. Such data may include your behavior towards various
          content posted on our Website/App from other sources. We use the data
          collected from other sources to prepare a specific diet plan and
          training regime for you. We also use this data for providing, testing,
          improving, or recommending the Services.
        </Description>

        <HeadingText>5. RETENTION OF PERSONAL INFORMATION</HeadingText>
        <Description>
          We will store any personal data we collect from you as long as it is
          necessary in order to facilitate your use of the Services and for
          ancillary legitimate and essential business purposes – these include,
          without limitation, for improving our Services, attending to technical
          issues, and dealing with disputes.
          <br />
          Your personal data will primarily be stored in electronic form. We may
          enter into an agreement with third parties to collect, store, process
          your personal data but under full compliance with applicable laws. In
          the event, you have any telephonic interactions with our customer
          representatives, the call data is recorded and stored on the Amazon
          Web Services (AWS) for training and quality purposes.
          <br />
          We may need to retain your personal data even if you seek deletion
          thereof if it is needed to comply with our legal obligations, resolve
          disputes, and enforce our agreements. If you are a Registered User,
          please be advised that after you terminate your usage of a Service,we
          may, unless legally prohibited, delete all data provided or collected
          by you from our servers.
        </Description>

        <HeadingText>6. TRACKING BY OUR ADVERTISEMENT PARTNERS</HeadingText>
        <Description>
          Upon receiving your specific consent, we may share the data we collect
          from cookies or web beacons with our advertisement partners to track
          your visits, establish your non-personal identity, and present you
          with targeted advertisements about our Services.
        </Description>

        <HeadingText>
          7. COMPLIANCE WITH CHILDREN’S ONLINE PRIVACY PROTECTION ACT
        </HeadingText>
        <Description>
          As a business service, we neither knowingly collect nor store nor use
          any personal data of any individuals under the age of 13. We do not
          target our Services toward individuals under the age of 13.
          Individuals under the age of 13 should seek the consent of their
          parents before providing any information about themselves, their
          parents, or other family members on our Website/App.
        </Description>

        <HeadingText>8. TRANSFER OF INFORMATION</HeadingText>
        <Description>
          In the ordinary course of business, we may employ other companies and
          people to assist us in providing certain components of our Services in
          compliance with the provisions of this Policy. To do so, we may need
          to share your data with them.
          <br />
          Where applicable – if the entities to which these transfers are
          effected are not situated in countries deemed ‘adequate’ by the
          European Commission or are not registered under the EU-US Privacy
          Shield framework, we shall enter into appropriate Data Protection
          Addendums with the transferee parties that comprehensively protect
          your data. We shall also put in place industry-standard technical and
          organizational measures (including robust data handling policies) to
          ensure that such transfers are completed in accordance with applicable
          laws.
          <br />
          Some of the examples of where we may subcontract processing activities
          to third parties include —record keeping, data analysis, marketing
          assistance, processing payments, and providing customer service.
        </Description>

        <HeadingText>9. THIRD PARTY LINKS</HeadingText>
        <Description>
          We may display links to third-party websites or applications on our
          Website or App for advertising or providing you with relevant content.
          We will not be responsible for such third-party websites or
          applications if you choose to access them. If you provide any data to
          such website/application, please ensure you read their policies given
          that you will no longer be bound by this Policy in doing so.
          <br />
          We may receive data whenever you visit a third-party link through our
          Website/App which includes the date and time of your visit to the
          third-party website, the web address or URL, technical information
          about the IP address, browser, and the operating system you use and,
          if you are logged into Fit Silhouette, your username.
        </Description>

        <HeadingText>10. COMPELLED DISCLOSURE</HeadingText>
        <Description>
          In addition to the purposes set out in the Policy, we may disclose any
          data we collected or processed from you if it is required: under
          applicable law or to respond to a legal process, such as a search
          warrant, court order, or subpoena;
          <br />
          to protect our safety, your safety or the safety of others or in the
          legitimate interest of any party in the context of national security,
          law enforcement, litigation, criminal investigation or to prevent
          death or imminent bodily harm;
          <br />
          to investigate fraud, credit risk or violation of our Acceptable Use
          Policy; in connection with legal proceedings brought against Fit
          Silhouette, its officers, employees, affiliates, customers or vendors;
          <br />
          to establish, exercise, protect, defend and enforce our legal rights;
          or when we do a business deal or negotiate a business deal, or our
          assets are merged or acquired by the other business entity, or during
          restructuring of business or re-organization, we may have to share
          information provided by you with the other business entities.
        </Description>

        <HeadingText>11. SECURITY OF YOUR PERSONAL INFORMATION</HeadingText>
        <Description>
          We implement industry-standard technical and organizational measures
          by using a variety of security technologies and procedures to help
          protect your data from unauthorized access, use, loss, destruction, or
          disclosure. When we collect particularly sensitive data (such as a
          credit card number or your geo-location), it is encrypted using
          industry-standard cryptographic techniques including but not limited
          to PBKDF2, AES256, TLS1.2 & SHA256.
          <br />
          The collection, storage, usage, and sharing of your data by the
          Company shall be in compliance with the Information Technology
          (Reasonable Security Practices and Procedures and Sensitive Personal
          Data or Information) Rules, 2011, and other applicable laws.
          <br />
          Your password is your first line of defense. Once you set up a Fit
          Silhouette account, we recommend that you set a strong password which
          you never share with anyone.
          <br />
          We have taken appropriate steps for the security and protection of all
          our digital platforms, including internal applications. However, we
          shall not be responsible for any breach of security or the disclosure
          of personal data for reasons outside our control, such as hacking,
          social engineering, cyber-terrorism, espionage by third parties, or
          any events by way of force majeure such as sabotage, fire, flood,
          explosion, acts of God, civil commotion, strikes or industrial action
          of any kind, riots, insurrection, war, or acts of government.
        </Description>

        <HeadingText>12. ACCESS, CORRECTION & DELETION</HeadingText>
        <Description>
          You may request access, correction, or updation, and deletion of the
          data by contacting complaints@fitsilhouette.com. You may note that
          deletion of certain data or withdrawal of consent may lead to the
          cancellation of your registration with us or your access to our
          Services. Based on technical feasibility, we will provide you with
          access to all your personal and sensitive personal data that we
          maintain about you. We will perform verification before providing you
          access to this data.
          <br />
          You may opt out of our marketing emails by clicking on the ‘opt-out’
          link provided in the emails. However, we may still send you
          non-marketing emails about your accounts or any other transactions
          with you.
        </Description>

        <HeadingText>13. GRIEVANCE OFFICER</HeadingText>
        <Description>
          NAME : Farhan Mirza
          <br />
          ADDRESS : 46, Badhoon, Rajouri, Jammu and Kashmir - 185132, India
          <br />
          EMAIL : complaints@fitsilhouette.com
        </Description>
      </MainDiv>
    </MainDiv>
  );
}

export default Privacy;

const MainDiv = styled.div`
  align-self: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const HeadingText = styled.h1`
  font-size: 20px;
  color: black;

  font-family: Gilroy-Regular, Helvetica;
  font-weight: 700;
  margin-top: 40px;
`;
const Description = styled.h1`
  font-size: 17px;
  color: black;

  font-family: Gilroy-Regular, Helvetica;
  font-weight: 500;
  margin-top: 5px;
`;

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const TableHeader = styled.th`
  background-color: #f2f2f2;
  border: 1px solid #ccc;
  padding: 10px;

  font-family: Gilroy-Regular, Helvetica;
  text-align: left;
`;

const TableData = styled.td`
  border: 1px solid #ccc;
  padding: 8px;

  font-family: Gilroy-Regular, Helvetica;
  text-align: left;
`;
const TableRow = styled.tr``;
