import React from "react";
import styled from "styled-components";
import image3_1 from "../../images/image3_1.png";
import image3_2 from "../../images/image3_2.png";

function OurFeaturesCard() {
  return (
    <RowDiv>
      <Imageview1>
        <Trackers src={image3_1} alt="Description of the image" />
      </Imageview1>
      <Imageview1
        style={{
          width: window.innerWidth > 768 ? "28.04vw" : " 75vw",
          marginLeft: window.innerWidth > 768 ? "4.55vw" : "0px",
          marginTop: window.innerWidth > 768 ? "0px" : "20px",
        }}
      >
        <Trackers
          style={{ width: "100%" }}
          src={image3_2}
          alt="Description of the image"
        />
      </Imageview1>
    </RowDiv>
  );
}

export default OurFeaturesCard;

const RowDiv = styled.div`
  width: 100vw;
  height: auto;
  margin-top: 9vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    height: auto;
    padding-bottom: 20px;
  }
`;

const Imageview1 = styled.div`
  height: auto;
  width: 32.5vw;
  position: relative;

  @media (max-width: 768px) {
    width: 80vw;
    height: auto;
  }
`;

const Trackers = styled.img`
  height: 100%;
  left: 0.39vw;
  top: 4.5vh;
  bottom: 4.36vh;
  width: 100%;

  @media (max-width: 768px) {
    top: 30px;
  }
`;
