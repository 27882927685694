import React from "react";
import { styled } from "styled-components";
// import logo from "../../images/logo.webp";

function Shipping() {
  return (
    <MainDiv style={{ width: "100%", alignItems: "center" }}>
      {/* <img
        src={
          // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
          logo
        }
        alt="Description of the image"
        height={"90px"}
        width={"90px"}
        // width={"00px"}
        // style={{ marginLeft: 30, marginRight: 10 }}
      /> */}
      <MainDiv
        style={{
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        <HeadingText>Shipping & Delivery Policy</HeadingText>
        <Description>
          Effective Date: September 9,2023
          <br />
          <br />
          Welcome to Fit Silhouette! We are committed to providing you with the
          best fitness products and services to support your fitness journey.
          This Shipping & Delivery Policy outlines our procedures, methods, and
          timelines for shipping and delivering products or services to our
          valued customers.
        </Description>

        <HeadingText>Shipping Methods and Associated Costs:</HeadingText>
        <Description>
          At Fit Silhouette, we offer various shipping methods to cater to your
          needs and preferences. The available shipping options may include:
          <br />• Standard Shipping: This is our default shipping method, and it
          is usually the most cost-effective option. Standard shipping costs may
          vary depending on your location and the weight/size of the product.
          <br />• Express Shipping: For customers who require faster delivery,
          we offer express shipping at an additional cost. The exact pricing and
          delivery timeline for express shipping will be displayed during the
          checkout process.
          <br />• Free Shipping: Occasionally, we may offer free shipping
          promotions for specific products or order values. If you qualify for
          free shipping, it will be clearly indicated at the time of purchase.
        </Description>

        <HeadingText>
          Estimated Delivery Times and Factors Affecting Them:
        </HeadingText>
        <Description>
          Our estimated delivery times are calculated based on several factors,
          including:
          <br />• Product Availability: Some products may require additional
          processing time if they are not in stock at the time of your order. We
          will notify you if there are any delays in processing your order.
          <br />• Shipping Destination: The delivery time may vary based on your
          location. Please refer to the estimated delivery times provided during
          the checkout process.
          <br />• Customs and Import Procedures: If you are ordering from
          outside our home country, your order may be subject to customs
          clearance procedures and import duties. These factors can affect the
          delivery timeline, and we appreciate your patience during this
          process.
        </Description>

        <HeadingText>Order Tracking Options and Instructions:</HeadingText>
        <Description>
          We understand the importance of tracking your order to keep you
          informed about its status. Fit Silhouette provides order tracking
          options to help you monitor the progress of your delivery. Here's how
          you can track your order:
          <br />• Order Confirmation: After placing your order, you will receive
          an order confirmation email that includes a unique order ID. Please
          keep this ID handy as it will be required for tracking.
          <br />• Tracking Link: When your order is shipped, we will send you a
          shipping confirmation email with a tracking link. Click on the link to
          access real-time updates on the location and status of your package.
          <br />• Account Dashboard: If you have a Fit Silhouette account, you
          can log in to your account and access the "Order History" section.
          There, you will find a list of your recent orders, including tracking
          information. Please note that tracking information may take some time
          to become available after your order is shipped. If you encounter any
          issues or have questions about tracking your order, our customer
          support team is here to assist you. You can reach out to us at
          Support@fitsilhouette.com
        </Description>

        <HeadingText>Important Note:</HeadingText>
        <Description>
          Our shipping and delivery policies comply with the regulations and
          guidelines mandated by the Reserve Bank of India (RBI). We adhere to
          these rules during our verification and shipping processes to ensure a
          secure and compliant experience for our customers.
          <br />
          <br />
          Thank you for choosing Fit Silhouette. We value your trust and are
          dedicated to providing you with a seamless shopping experience. If you
          have any further inquiries or require assistance, please do not
          hesitate to contact our customer support team.
          <br />
          Please note that this Shipping & Delivery Policy is subject to change,
          and any updates will be posted on our website and communicated to
          users as necessary
        </Description>
      </MainDiv>
    </MainDiv>
  );
}

export default Shipping;

const MainDiv = styled.div`
  align-self: center;
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const HeadingText = styled.h1`
  font-size: 20px;
  color: black;

  font-family: Gilroy-Regular, Helvetica;
  font-weight: 700;
  margin-top: 40px;
`;
const Description = styled.h1`
  font-size: 17px;

  font-family: Gilroy-Regular, Helvetica;
  color: black;
  font-weight: 500;
  margin-top: 5px;
`;
