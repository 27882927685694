import React from "react";
import { styled } from "styled-components";
// import logo from "../../images/logo.webp";

function Aboutus() {
  return (
    <MainDiv style={{ width: "100%", alignItems: "center" }}>
      {/* <img
        src={
          // "https://storage.googleapis.com/gweb-uniblog-publish-prod/original_images/Fit_Marketing_1.gif"
          logo
        }
        alt="Description of the image"
        height={"90px"}
        width={"90px"}
        // width={"00px"}
        // style={{ marginLeft: 30, marginRight: 10 }}
      /> */}
      <MainDiv
        style={{
          width: window.innerWidth > 768 ? "60%" : "95%",
        }}
      >
        <HeadingText>About Fit Silhouette</HeadingText>
        <Description>
          Welcome to Fit Silhouette, where passion meets performance, and
          fitness becomes a lifestyle. Our mission is to empower you on your
          journey towards a healthier, happier you. At Fit Silhouette, we
          believe that fitness goes beyond what you wear—it's about nourishing
          your body, staying active, and achieving your wellness goals.
        </Description>

        <HeadingText>Your Complete Wellness Partner</HeadingText>
        <Description>
          Fit Silhouette is more than just an activewear brand; we're your
          comprehensive wellness partner. We're committed to helping you achieve
          your fitness objectives through personalised diet plans, expertly
          crafted workout plans, and intuitive health trackers.
        </Description>

        <HeadingText>Diet Plans & Nutrition</HeadingText>
        <Description>
          We understand that a healthy diet is fundamental to your fitness
          journey. Fit Silhouette offers personalised diet plans tailored to
          your goals and dietary preferences. Whether you're looking to lose
          weight, gain muscle, or maintain a balanced diet, we've got you
          covered.
        </Description>

        <HeadingText>Workout Plans & AI Guidance</HeadingText>
        <Description>
          Our AI-based workout plans are designed to keep you motivated and on
          track. Whether you're a beginner or a seasoned athlete, we'll help you
          reach your fitness goals. Our AI assistance ensures that your workouts
          are effective, safe, and enjoyable.
        </Description>

        <HeadingText>Health Tracking Made Easy</HeadingText>
        <Description>
          Stay on top of your health with our range of trackers, including
          calorie counters, water intake monitors, step counters, and even a
          female period tracker. Monitor your progress and make informed
          decisions to optimise your well-being.
        </Description>

        <HeadingText>Restaurant Integration for Healthy Dining</HeadingText>
        <Description>
          Eating out? No problem. Fit Silhouette seamlessly integrates with
          restaurants, helping you make healthier dining choices at the comfort
          of you home. Access nutrition information and make mindful selections
          that align with your fitness goals.
        </Description>

        <HeadingText>Your Well-Being Matters</HeadingText>
        <Description>
          We care deeply about your well-being. Fit Silhouette provides health
          status displays, allowing you to track your progress and make informed
          choices. Our AIbacked background support offers guidance and insights
          to keep you on the path to a healthier, happier you.
        </Description>

        <HeadingText>Premium Activewear</HeadingText>
        <Description>
          While our primary focus is your wellness, we also offer premium
          activewear designed to complement your fitness journey. Our clothing
          is crafted with a blend of functionality and fashion, ensuring you
          look and feel your best during workouts.
        </Description>
        <HeadingText>Join Our Community</HeadingText>
        <Description>
          Become a part of the Fit Silhouette community, where like-minded
          individuals come together to share experiences, tips, and motivation.
          Connect with us on social media, join our events, and be part of a
          community that celebrates wellness as a way of life.
        </Description>

        <HeadingText>Thank You</HeadingText>
        <Description>
          Thank you for choosing Fit Silhouette as your complete wellness
          partner. We're excited to support you on your journey to optimal
          health and well-being. Together, let's make fitness a lifestyle.
        </Description>
      </MainDiv>
    </MainDiv>
  );
}

export default Aboutus;

const MainDiv = styled.div`
  align-self: center;
  /* margin-top: 50px; */
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;
const HeadingText = styled.h1`
  font-size: 20px;
  color: black;
  font-family: Gilroy-Regular, Helvetica;
  font-weight: 700;
  margin-top: 40px;
`;
const Description = styled.h1`
  font-size: 17px;
  color: black;
  font-family: Gilroy-Regular, Helvetica;
  font-weight: 500;
  margin-top: 5px;
`;
